import React, { PureComponent } from 'react';
import classNames from './index.module.css';
import { Props, State } from './interface';

export default class Card extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {};
	}

	render() {
		const { titleName, style } = this.props;
		return (
			<>
				<div className={classNames.pageCard} style={style}>
					<div className={classNames.title}>{titleName}</div>
					<div className={classNames.cardContainer}>{this.props.children}</div>
				</div>
			</>
		);
	}
}
