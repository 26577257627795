import React, { useRef, useState, useImperativeHandle, forwardRef } from 'react';
import classNames from './index.module.css';
import SpaceDesc from './spaceDesc';
import AccessInfo from '@/pages/overview/accessInfo';
import TrafficFlow from '@/pages/overview/trafficFlow';

interface Props {
	pageInfo?: any;
}
const LeftPage = (props: Props, ref: any) => {
	const parkDeviceRef = useRef<any>(null);
	const { pageInfo } = props;
	useImperativeHandle(ref, () => {
		return {
			initThree
		};
	});

	const initThree = () => {
		parkDeviceRef?.current?.getParkDevice();
	};
	return (
		<div className={classNames.pageContent}>
			<div style={{ height: '26%' }}>
				<SpaceDesc pageInfo={pageInfo}></SpaceDesc>
			</div>
			<div style={{ height: '37%', marginTop: '20rem' }}>
				<TrafficFlow data={pageInfo.FLOW} />
			</div>
			<div style={{ height: '30%', marginTop: '20rem' }}>
				<AccessInfo
					todayPeopleOut={pageInfo?.IN_OUT_RECORD?.todayPeopleOut || []}
					todayPeopleIn={pageInfo?.IN_OUT_RECORD?.todayPeopleIn || []}
					todayCarIn={pageInfo?.IN_OUT_RECORD?.todayCarIn || []}
					todayCarOut={pageInfo?.IN_OUT_RECORD?.todayCarOut || []}
				/>
			</div>
		</div>
	);
};

export default forwardRef(LeftPage);
