function seriesCreator(series: any) {
	return series.map((e: any) => ({
		type: 'bar',
		...e
	}));
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (
	option: { tooltip: any },
	data: { [x: string]: any; tooltip: any; xAxis: any; yAxis: any; yCategory: any; series?: never[] | undefined }
) {
	const { tooltip, xAxis, yAxis, yCategory, series = [], ...rest } = data;
	return {
		...option,
		xAxis,
		yAxis,
		tooltip: {
			...option.tooltip,
			...tooltip
		},
		series: seriesCreator(series),
		...rest
	};
}
