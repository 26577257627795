import { toDecimal2 } from '@/utils';

export const optionDate = (total: any, detail: any) => {
	// console.log('(detail)', detail);
	const option = {
		// tooltip: {
		// 	show: true,
		// 	formatter: '{b} : ' + toDecimal2(value) + '%'
		// },
		color: ['#14D8CA', '#FFC13D'],
		title: {
			text: total,
			// left: '45%',

			textAlign: 'center',
			top: '30%',
			left: '49%',
			textStyle: {
				width: 70,
				fontSize: 18,
				height: 18,
				lineHeight: 18,
				color: '#06E7E9',
				overflow: 'truncate',
				ellipsis: '...'
			},
			subtext: '车位总数',
			subtextStyle: {
				color: '#fff',
				fontSize: 10,
				lineHeight: 0,

				align: 'left'
			}
		},
		series: [
			{
				type: 'pie',
				radius: [34, 47],

				// height: '90%',
				left: 'center',
				width: 330,
				itemStyle: {
					// borderColor: '#fff',
					// borderWidth: 1,
				},

				label: {
					alignTo: 'edge',
					formatter: (data: any) => {
						return '{name|' + data.name + '}{time|' + data.value + '}个\n{time|' + data.percent + '%}';
					},
					minMargin: 4,
					edgeDistance: 6,
					lineHeight: 15,
					color: '#fff',
					rich: {
						time: {
							fontSize: 10,
							color: '#06E7E9'
						}
					}
				},
				labelLine: {
					length: 25,
					length2: 0,
					maxSurfaceAngle: 80
				},
				labelLayout: function (params: any) {
					const isLeft = params.labelRect.x < 165;
					const points = params.labelLinePoints;
					// Update the end point.
					points[2][0] = isLeft ? params.labelRect.x : params.labelRect.x + params.labelRect.width;
					return {
						labelLinePoints: points
					};
				},
				data: [
					{
						name: '固定车位',
						value:
							detail?.length && detail?.length >= 2
								? detail[0].carLotType === '固定车位'
									? detail[0].carLotNum
									: detail[1].carLotNum
								: 0
					},
					{
						name: '公共车位',
						value:
							detail?.length && detail?.length >= 2
								? detail[0].carLotType === '公共车位'
									? detail[0].carLotNum
									: detail[1].carLotNum
								: 0
					}
				]
			}
		]
	};
	return option;
};
