import React from 'react';
import './index.less';
import classNames from './index.module.css';
import Card from '@/components/Card';

interface Props {
	pageInfo?: any;
}
const SecurityOverview = (props: Props) => {
	const { pageInfo } = props;
	const securityList: any = [
		{
			key: 'blackListPerson',
			name: '黑名单人员',
			value: 0,
			unit: '人'
		},
		{
			key: 'blackListCar',
			name: '黑名单车辆',
			value: 0,
			unit: '辆'
		},
		{
			key: 'securityIncident',
			name: '安防事件',
			value: 0,
			unit: '件'
		},
		{
			key: 'securityDev',
			name: '安防设备',
			value: 0,
			unit: '件'
		}
	];

	const requireData = () => {
		if (pageInfo && pageInfo.PARK_SECURITY) {
			securityList.map((item: any) => {
				const list = item;
				const info = pageInfo.PARK_SECURITY[list.key] || '0';
				if (item.unit) {
					list.value = `${info}`;
				} else {
					list.value = info;
				}
			});
		}
		return securityList || [];
	};

	return (
		<Card titleName="安防概况" style={{ height: '100%' }}>
			<div className={classNames.securityList}>
				{requireData().map((item: any) => {
					return (
						<div className={classNames.item} key={item.key}>
							<p className={classNames.itemName}>{item.name}</p>
							<span className={classNames.itemNumber}>
								{item.value}
								<span className={classNames.unit}>{item.unit}</span>
							</span>
						</div>
					);
				})}
			</div>
		</Card>
	);
};

export default SecurityOverview;
