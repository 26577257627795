import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Image, Tooltip } from 'antd';
import {
	highRiseLitteringOptions,
	electronicPatrolOptions,
	videoPatrolReportOptions,
	deviceStatusAlertOptions,
	deviceRepairOptions,
	defaultOptions
} from './evenTypeOptions';
import { IDevItem, IOptionItem } from './interface';
import { EventTyptEnum, LocationEnum, TaskTypeEnum } from '@/pages/security/eventTypes/interface';
import classNames from './index.module.css';
import VideoPlayer from '../videoPlayer';
import moment from 'moment';
import { getImageSrc } from '@/utils';
import { getFileType } from './utils';
import { locationMap, taskTypeMap, timeLimitMap } from '@/pages/security/eventTypes/constant';
import { BaseData } from '@/api';
function RenderVideo({
	processValues,
	optionItem,
	videoPlayerRef
}: {
	processValues: AnyObject;
	optionItem: IOptionItem;
	videoPlayerRef: any;
}) {
	const curTime = processValues.createTime ? processValues.createTime : processValues.time;
	return (
		<VideoPlayer
			id={processValues[optionItem.value]}
			videoRef="video-patrol-player"
			onRef={(ref: any) => (videoPlayerRef.current = ref)}
			type={'hls'}
			startTime={moment(new Date(curTime).getTime() - 10000).format('YYYY-MM-DD HH:mm:ss')}
			endTime={moment(new Date(curTime).getTime() + 10000).format('YYYY-MM-DD HH:mm:ss')}
		/>
	);
}
const deviceRowData: { label: string; key: keyof IDevItem }[] = [
	{
		label: '设备名称',
		key: 'name'
	},
	{
		label: '设备类型',
		key: 'type'
	},
	{
		label: '设备区域',
		key: 'location'
	},
	{
		label: '详细地址',
		key: 'detailLocation'
	}
];
const ModalSecurityEventDetail = ({
	eventType,
	dataSource
}: {
	eventType: { name: string; key: string };
	dataSource: AnyObject;
}) => {
	const videoPlayerRef = useRef<any>(null);
	const [processValues, setProcessValues] = useState<any>({});
	useEffect(() => {
		let isUnmount = false;
		const { processVariables = {} } = dataSource;
		if (Object.keys(dataSource).length) {
			setProcessValues(processVariables);
		}
		if (!isUnmount && eventType.key === EventTyptEnum.DEVICE_ALERT) {
			// 设备预警类型事件需要根据设备编码查询设备信息，将请求结果与传入的事件详情数据进行合并
			BaseData.getDevDetail({
				path: [processVariables.devId]
			}).then((res: IotResponse) => {
				if (!res.code) setProcessValues({ ...processVariables, ...processVariables.data, ...res.data });
			});
		}
		if (eventType.key === EventTyptEnum.DEVICE_STATUS_ALERT) {
			setProcessValues({ ...processVariables, ...processVariables.data });
		}
		return () => {
			isUnmount = true;
		};
	}, [eventType, dataSource]);
	const crtOptions: IOptionItem[] = useMemo(() => {
		const fixDeviceOption = (arr: IOptionItem[]) => {
			const optionRes: IOptionItem[] = arr.reduce((option: IOptionItem[], oi) => {
				if (Object.keys(processValues).length && !processValues.hasOwnProperty(oi.value)) {
					if (oi.value === 'address') oi.value = 'location';
					if (oi.value === 'alarmName') oi.value = 'typeName';
					if (oi.value === 'reportTime') oi.value = 'time';
				}
				if (
					(processValues.videoType === LocationEnum.PUBLIC &&
						oi.value !== 'carParkName' &&
						oi.value !== 'buildName') ||
					(processValues.videoType === LocationEnum.CARPARK && oi.value !== 'buildName') ||
					(processValues.videoType === LocationEnum.BUILD && oi.value !== 'carParkName')
				)
					option.push(oi);
				return option;
			}, []);
			return optionRes;
		};
		const getCrtOptions = (): IOptionItem[] => {
			switch (eventType.key) {
				case EventTyptEnum.HIGH_RISE_LITTERING:
					return highRiseLitteringOptions;
				case EventTyptEnum.ELECTRONIC_PATROL:
					return electronicPatrolOptions;
				case EventTyptEnum.VIDEO_PATROL_REPORT:
					return videoPatrolReportOptions;
				case EventTyptEnum.DEVICE_STATUS_ALERT:
					return fixDeviceOption(deviceStatusAlertOptions);
				case EventTyptEnum.DEVICE_ALERT:
					return fixDeviceOption(deviceStatusAlertOptions);
				case EventTyptEnum.DEVICE_REPAIR:
					return processValues.taskType === TaskTypeEnum.URGENT_WORK_ORDER
						? deviceRepairOptions.filter((oi) => oi.value !== 'timeLimit')
						: deviceRepairOptions;

				default:
					return defaultOptions;
			}
		};
		return getCrtOptions();
	}, [eventType.key, processValues]);
	return (
		<div className={classNames.content}>
			{crtOptions.map((optionItem, index) => (
				<div
					key={index}
					className={
						optionItem.type === 'video' ||
						optionItem.type === 'image' ||
						optionItem.type === 'imageAndvideo' ||
						optionItem.type === 'fileDownLoad' ||
						optionItem.type === 'table' ||
						optionItem.type === 'fullRow'
							? classNames.fullRowOptionItem
							: classNames.halfRowOptionItem
					}
				>
					<div className={classNames.itemTitle}>{optionItem.label}</div>
					{optionItem.value === 'videoType' ? (
						locationMap[processValues[optionItem.value]]
					) : optionItem.value === 'taskType' ? (
						taskTypeMap[processValues[optionItem.value]]
					) : optionItem.value === 'timeLimit' ? (
						processValues[optionItem.value] === '0' ? (
							timeLimitMap[processValues[optionItem.value]]
						) : (
							processValues[optionItem.value]
						)
					) : optionItem.type === 'video' ? (
						<RenderVideo
							processValues={processValues}
							optionItem={optionItem}
							videoPlayerRef={videoPlayerRef}
						/>
					) : optionItem.type === 'image' ? (
						<div className={classNames.mediaContainer}>
							{processValues?.faultFile?.length ? (
								processValues?.faultFile
									.split(',')
									.map((srcUrl: string, urlIdx: number) => (
										<Image key={urlIdx} width="138px" height="160px" src={getImageSrc(srcUrl)} />
									))
							) : (
								<div className="place-image">暂无图片</div>
							)}
						</div>
					) : optionItem.type === 'imageAndvideo' ? (
						<div className={classNames.mediaContainer}>
							{processValues?.faultFile?.length && processValues?.faultFile instanceof Array ? (
								processValues?.faultFile.map((faultFileItem: string) => {
									return faultFileItem
										.split(',')
										.map((srcUrl: string, urlIdx: number) =>
											getFileType(srcUrl) === 'video' ? (
												<RenderVideo
													key={urlIdx}
													processValues={processValues}
													optionItem={optionItem}
													videoPlayerRef={videoPlayerRef}
												/>
											) : (
												<Image
													key={urlIdx}
													width="138px"
													height="160px"
													src={getImageSrc(srcUrl)}
												/>
											)
										);
								})
							) : (
								<div className="place-image">暂无</div>
							)}
						</div>
					) : optionItem.type === 'fileDownLoad' ? (
						<div>
							{processValues[optionItem.value]?.length
								? processValues[optionItem.value].split(',').map((fileName: string) => (
										<a
											key={fileName}
											href={getImageSrc(fileName)}
											className={classNames.fileDownLoad}
										>
											{fileName}
										</a>
								  ))
								: null}
						</div>
					) : optionItem.type === 'table' && processValues[optionItem.value]?.length ? (
						eventType.key === EventTyptEnum.DEVICE_REPAIR ? (
							<div className={classNames.tableContainer}>
								<table className={classNames.tableContent}>
									<thead>
										<tr className={classNames.tableRow}>
											{deviceRowData.map((rowIrem) => (
												<th key={rowIrem.key} className={classNames.tableRowItem}>
													{rowIrem.label}
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{processValues[optionItem.value].map((devItem: IDevItem) => (
											<tr key={devItem.id} className={classNames.tableRow}>
												{deviceRowData.map((rowIrem) => (
													<td key={rowIrem.key} className={classNames.tableRowItem}>
														<Tooltip title={devItem[rowIrem.key]}>
															{devItem[rowIrem.key]}
														</Tooltip>
													</td>
												))}
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : null
					) : (
						<div>
							{processValues[optionItem.value]
								? `${processValues[optionItem.value]} ${
										optionItem.value === 'timeLimit' ||
										optionItem.value === 'receiveTimeLimit' ||
										optionItem.value === 'dealTimeLimit'
											? '分'
											: ''
								  }`
								: '暂无'}
						</div>
					)}
				</div>
			))}
		</div>
	);
};
export default memo(ModalSecurityEventDetail);
