export const optionList = [
	{
		label: '全部',
		value: ''
	},
	{
		label: '本月',
		value: '0'
	},
	{
		label: '近三月',
		value: '3'
	},
	{
		label: '近半年',
		value: '6'
	},
	{
		label: '近一年',
		value: '12'
	}
];
