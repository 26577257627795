const xDataArr: string[] = [];
for (let i = 0; i < 24; i++) {
	if (i < 10) {
		xDataArr.push('0' + i + ':00');
	} else {
		xDataArr.push(i + ':00');
	}
}

export const getOptionOne = (data: any[]) => {
	return {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow'
			}
		},
		legend: {
			show: true,
			x: 'center',
			bottom: 15,
			icon: 'roundRect',
			itemGap: 41,
			itemWidth: 9,
			itemHeight: 9,
			textStyle: {
				color: '#fff',
				fontSize: 14
			}
		},
		grid: {
			top: '12%',
			left: '6%',
			right: '4%',
			bottom: '20%',
			containLabel: true
		},
		xAxis: [
			{
				type: 'category',
				data: ['访客登记', '访客预约', '实际到访'],
				show: true,

				axisLabel: {
					show: true,
					color: '#fff'
				},
				axisLine: {
					onZero: false,
					show: true,
					lineStyle: {
						show: true,
						color: '#00A0E5'
					}
				}
			}
		],
		yAxis: [
			{
				type: 'value',
				show: true,

				axisLabel: {
					show: true,
					color: '#fff'
				},
				splitLine: {
					show: true,
					lineStyle: {
						show: true,
						color: 'rgba(0, 160, 229, 0.14)'
					}
				},
				axisLine: {
					onZero: false,
					show: true,
					lineStyle: {
						show: true,
						color: '#00A0E5'
					}
				}
			}
		],
		color: ['rgba(0, 160, 233, 1)'],
		series: [
			{
				type: 'bar',
				barWidth: 16,
				label: {
					color: 'rgba(255, 247, 153, 1)',
					show: true,
					position: 'top',
					formatter: (data: any) => {
						return '{name| ' + data.value + '}';
					},
					rich: {
						name: {
							color: 'rgba(255, 247, 153, 1)',
							fontSize: 23,
							fontFamily: 'LcdD',
							padding: [5, 0]
						}
					}
				},

				data: data || [0, 0, 0]
			}
		]
	};
};
