import React, { Component, useState, memo, useRef, useEffect, useMemo } from 'react';
import classes from './index.module.css';
import { Backend } from '@/api';
import PageModule from '@/template/PageModule';
import LeftOne from './components/leftOne';
import RightOne from './components/rightOne';
import { Backend as deviceApi, BaseData } from '@/api';
import { Checkbox, Modal } from 'antd';
import ModalPOI from '@/components/ModalPOI';
import * as redux from 'react-redux';
import './index.less';

import { storage } from '@/utils';
const { connect } = redux;
const checkList = [
	{
		name: '用水量',
		value: 'water'
	},
	{
		name: '用电量',
		value: 'el'
	}
];

interface Props {}
const EnergyConsumption = (props: any) => {
	const [rightPageInfo, setRightPageInfo] = useState<any>({});
	const [leftPageInfo, setLeftPageInfo] = useState<any>({});
	const [isShow, setIsShow] = useState<boolean>(true);
	const [select, setSelect] = useState<any>([]);
	const leftRef = useRef<any>(null);
	const rightRef = useRef<any>(null);

	const getData = () => {
		const currentArea = storage.getSession('CURRENTAREA');
		const parameter = {
			query: {
				bigScreenLable: 'GENERAL_SITUATION',
				areaId: currentArea === 'all' ? '' : currentArea
			}
		};
		Backend.screenData(parameter).then((res: IotResponse) => {
			if (res.code === 0) {
				const {
					PARK_SECURITY = {},
					PEOPLE_CAR = {},
					SPACE_INFO = {},
					AREA_INFO = {},
					CAR_GROUP = {},
					PEOPLE = {},
					ALL_CARLOT_RATE = {}
				} = res?.data;
				const rightPage = {
					PARK_SECURITY,
					parkPeopleVO: PEOPLE?.parkPeopleVO || {},
					parkCarVO: PEOPLE_CAR?.parkCarVO || {},
					CAR_GROUP,
					peopleFlowVO: PEOPLE?.peopleFlowVO || [],
					carFlowVO: PEOPLE?.carFlowVO || []
				};
				const LeftPageInfo = {
					SPACE_INFO,
					AREA_INFO,
					ALL_CARLOT_RATE
				};

				setRightPageInfo(rightPage);
				setLeftPageInfo(LeftPageInfo);
			}
		});
	};
	const init = () => {
		getData();

		leftRef && leftRef?.current?.initThree();
		rightRef && rightRef?.current?.initRightThree();
	};

	useEffect(() => {
		setSelect([]);
		return () => {
			const { GMapInstance } = window;
			GMapInstance?.removeMarker('build1');
			GMapInstance?.removeMarker('carpark1');
			GMapInstance?.removeMarker('build');
			GMapInstance?.removeMarker('carpark');
		};
	}, [props.currentArea]);

	const curShow = useMemo(() => isShow, [isShow]);
	const toggleShow = (flag: boolean, leftFlag: boolean) => {
		if (flag && leftFlag) {
			setIsShow(!isShow);
		}
	};

	// 楼宇车场打点
	const setBuildIcon = (area: string, type: string, pip: Function, videoType: string) => {
		const { currentArea } = props;
		const { GMapInstance } = window;
		// GMapInstance?.removeMarker(type);
		GMapInstance?.removeMarker('build1');
		GMapInstance?.removeMarker('carpark1');
		let str: string = `<div class="marker_total1">	<div><span></span>用电量（kW.h）</div>
				<div>本月：100</div>
        <div>今年：400</div>
				<div>总消耗：1000</div></div>`;
		if (select.includes('water')) {
			str = `<div class="marker_total1">	<div><span></span>用水量（t）</div>
				<div>本月：100</div>
        <div>今年：400</div>
				<div>总消耗：1000</div></div>`;
		}
		deviceApi
			.getBuildsByArea({
				query: {
					areaId: area,
					type: videoType
				}
			})
			.then((res: IotResponse) => {
				if (res.code === 0) {
					const data = res.data;

					const result: any[] = [];
					data?.buildingDetails?.map((item: any, index: number) => {
						const temp = pip(item);

						temp.listener = {
							click: () => {
								BaseData.getScreenBuildInfo({
									path: [item.id]
								}).then((res: IotResponse) => {
									if (res.code === 0) {
										const resData = res.data;
										const data: PoiModalData = {
											title: item.buildAreaName + '-' + item.buildName,
											defaultData: [
												{
													label: '楼宇名称',
													value: resData.buildName
												},
												{
													label: '楼宇类型',
													value: resData.buildTypeName
												},
												{
													label: '占地面积',
													value: resData.buildMeasure + '/㎡'
												},
												{
													label: '单元数',
													value: resData.unitNums || ''
												},
												{
													label: '房屋数',
													value: resData.houseNums || ''
												},
												{
													label: '企业数',
													value: resData.businessNums
												},
												// {
												// 	label: '入住人数',
												// 	value: resData.occupantNums
												// },
												{
													label: '楼宇电耗',
													value: resData.energyNums
												},
												{
													label: '图片',
													value: ''
												}
											]
										};
										Modal.info({
											className: 'modal-poi-view',
											content: <ModalPOI dataSource={data} />,
											getContainer: false,
											mask: false,
											maskClosable: true,
											width: '420rem',
											onCancel: () => {
												GMapInstance.map?.setCenter(GMapInstance.center);
											}
										});
									}
								});
							}
						};

						result.push(temp);

						if (index < 3) {
							result.push({
								longitude: item.longitude,
								latitude: item.latitude,
								id: String(item.id + 1223315),
								icon: str,
								type: 'build1',
								zIndex: 600,
								listener: {
									click: () => {
										if (!item?.total) {
											return null;
										}
									}
								}
							});
						}

						return item;
					});
					// GMapInstance?.removeMarker('carpark');
					data?.carParkDetails?.map((item: any, index: number) => {
						const temp: any = {
							longitude: item.longitude,
							latitude: item.latitude,
							id: String(item.id),
							icon: `<img src="${require('@/static/images/carparkpoiicon.png').default}" />`,
							name: item.parkName,
							type: 'carpark'
						};
						temp.listener = {
							click: () => {
								BaseData.getScreenCarParkInfo({
									path: [item.id]
								}).then((res: IotResponse) => {
									if (res.code === 0) {
										const resData = res.data;
										const data: PoiModalData = {
											title: item.areaName + '-' + item.parkName,
											defaultData: [
												{
													label: '车场名称',
													value: item.parkName
												},
												{
													label: '所属区域',
													value: item.areaName
												},
												{
													label: '详细地址',
													value: resData.parkAddress
												},
												{
													label: '责任人',
													value: resData.parkPersonName
												},
												{
													label: '联系电话',
													value: resData.telephone
												},
												{
													label: '车位总数',
													value: resData.parkSpaceNum || 0
												},
												{
													label: '充电车位',
													value: resData.underParkSpaceNum || 0
												},
												{
													label: '当前停车数',
													value: '1'
												},
												{
													label: '图片',
													value: ''
												}
											]
										};
										Modal.info({
											className: 'modal-poi-view',
											content: <ModalPOI dataSource={data} />,
											getContainer: false,
											mask: false,
											maskClosable: true,
											width: '420rem',
											onCancel: () => {
												GMapInstance.map?.setCenter(GMapInstance.center);
											}
										});
									}
								});
							}
						};

						result.push(temp);
						if (index < 0) {
							result.push({
								longitude: item.longitude,
								latitude: item.latitude,
								id: String(item.id + 66655811),
								icon: `<div class="marker_total1">	<div><span></span>用电量（kW.h）</div>
                	<div>今年：400</div>
				<div>本月：100</div>
				<div>总消耗：1000</div></div>`,
								name: item.parkName,
								type: 'carpark1',
								listener: {
									click: () => {
										if (!item?.total) {
											return null;
										}
									}
								}
							});
						}
						return item;
					});

					GMapInstance?.drawMarkers(result);
				}
			});
	};

	useEffect(() => {
		const { GMapInstance } = window;
		if (select?.length) {
			setBuildIcon(
				props.currentArea === 'all' ? '' : props.currentArea,
				'build',
				(e: any) => {
					// console.log(e);
					if (e.longitude && e.latitude) {
						return {
							longitude: e.longitude,
							latitude: e.latitude,
							id: String(e.id),
							icon: `<img src="${require('../../static/images/buildpoiicon.png').default}" />`,
							name: e.buildName,
							type: 'build'
						};
					}
				},
				'‘WATERBOX’'
			);
		} else {
			if (props.currentArea === 'all') {
				GMapInstance?.removeMarker('build');
				GMapInstance?.removeMarker('carpark');
			}
			GMapInstance?.removeMarker('build1');
			GMapInstance?.removeMarker('carpark1');
		}
	}, [select]);
	return (
		<PageModule pageInit={init} rightToggleFinish={toggleShow}>
			<div className={'leftView'} key="left">
				<LeftOne pageInfo={leftPageInfo} ref={leftRef}></LeftOne>
				<div className="device-check_box">
					<Checkbox.Group value={select}>
						{checkList.map((item: any, index: number) => {
							return (
								<Checkbox
									value={item.value}
									key={index}
									onChange={(e) => {
										if (select.includes(e.target.value)) {
											setSelect([]);
										} else {
											setSelect([e.target.value]);
										}
									}}
								>
									{item.name}
								</Checkbox>
							);
						})}
					</Checkbox.Group>
				</div>
			</div>
			<div className={'rightView'} key="right">
				<RightOne pageInfo={rightPageInfo} ref={rightRef}></RightOne>
			</div>
		</PageModule>
	);
};

const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: Function) => {
	return {
		dispatch
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(EnergyConsumption);
