import { finished } from 'node:stream';
import * as echarts from 'echarts';
export const getOption = (data: any, axData: string[]) => {
	const XList = axData || [];
	const all: any[] = [];
	const finish: any[] = [];
	let date1Num: any;
	let date2Num: any;
	let date3Num = 120;
	const allNum: any = [];
	const finishedNum: any = [];
	XList.forEach((element: any) => {
		const FINISHED = data.finished || [];
		const All = data.all || [];
		const currentFINISHED = FINISHED.find((item: { name: any }) => {
			return item.name === element;
		});
		const currentALL = All.find((item: { name: any }) => {
			return item.name === element;
		});
		all.push(currentALL || 0);
		finish.push(currentFINISHED || 0);
	});
	all?.map((item) => {
		allNum.push(item?.value || item);
	});
	finish?.map((item) => {
		finishedNum.push(item?.value || item);
	});

	if (allNum?.length) {
		date1Num = allNum?.sort((a: number, b: number) => {
			return b - a;
		})[0];
	}
	if (finishedNum?.length) {
		date2Num = finishedNum?.sort((a: number, b: number) => {
			return b - a;
		})[0];
	}
	if (date2Num !== 0 || date1Num !== 0) {
		date3Num = date2Num > date1Num ? date2Num || 120 : date1Num || 120;
	}

	const dataText = [
		{
			name: '',
			type: 'bar',
			barWidth: 14,
			smooth: true,

			symbolSize: 4,
			data: [10, 50, 100, 50, 60, 100],
			itemStyle: {
				normal: {
					color: 'rgba(0, 160, 233, 1)'
				}
			},
			lineStyle: {
				width: 1,
				color: '#20eb52'
			},
			areaStyle: {
				normal: {
					color: new echarts.graphic.LinearGradient(
						0,
						0,
						0,
						1,
						[
							{
								offset: 0,
								color: '#27a46f'
							},
							{
								offset: 0.5,
								color: '#1f5b62'
							},
							{
								offset: 1,
								color: '#295a6e'
							}
						],
						false
					),
					shadowColor: '#257368',
					shadowBlur: 20
				}
			}
		}
	];

	return {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				// 坐标轴指示器，坐标轴触发有效
				type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
			}
		},
		legend: {
			show: false,
			x: 'center',
			bottom: '0',
			textStyle: {
				color: '#c1cadf',
				fontSize: 12
			}
		},
		grid: {
			left: '6%',
			right: '7%',
			top: '7%',
			bottom: '20%',
			containLabel: true
		},
		toolbox: {
			show: true,
			orient: 'vertical',
			x: 'right',
			y: 'center'
		},
		dataZoom: [
			{
				type: 'inside',
				start: 0,
				end: axData?.length > 7 ? 35 : 100
			}
		],
		xAxis: [
			{
				type: 'category',
				// boundaryGap: false,
				data: axData,
				triggerEvent: true,
				axisLine: {
					onZero: false,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// axisTick: {
				// 	inside: true
				// },
				axisLabel: {
					interval: 0,
					color: '#c1cadf',
					fontSize: '12'
				}
			}
		],
		yAxis: [
			{
				type: 'value',
				show: true,
				min: 0,
				max: date3Num,
				axisLine: {
					show: true,
					onZero: false,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// axisTick: {
				// 	inside: true
				// },
				axisLabel: {
					interval: 0,
					color: '#ffffff',
					fontSize: '12'
				},
				splitLine: {
					show: false
				}
			}
		],
		series: dataText
	};
};
