// eslint-disable-next-line import/no-anonymous-default-export
export default {
	tooltip: {
		backgroundColor: 'rgba(255,255,255,0.80)',
		borderColor: '#F1F1F1',
		borderWidth: 1,
		padding: [2, 4],
		textStyle: {
			color: '#666',
			fontSize: 12
		},
		extraCssText: 'box-shadow:0 1px 4px 0 rgba(0,0,0,0.20);border-radius:4px;',
		formatter: ''
	}
};
