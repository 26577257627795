import React, { useEffect, useState } from 'react';

import { useDrag, DragSourceMonitor } from 'react-dnd';
import { Tooltip } from 'antd';
const DragItem = (props: any) => {
	const [actives, SetActive] = useState('');
	const [refresh, setrefresh] = useState(false);
	//@ts-ignore
	const [{ isDragging, canDrag }, drag] = useDrag({
		item: {
			id: props.device.videoDevId,
			type: 'device',
			index: props.index,
			...props.device
			// id: props.id,
			// source: 'other',
			// icon: props.icon,
		},
		// canDrag: true,
		// type: 'device',
		options: {
			dropEffect: 'move'
		},
		collect: (monitor: DragSourceMonitor) => ({
			isDragging: monitor.isDragging(),
			canDrag: true
		}),
		// //@ts-ignore
		// begin: () => {
		// 	//
		// },
		end: (item, monitor) => {
			// const id = item.id;
			// const dom = document.getElementById(id);
			// //@ts-ignore
			// dom?.setAttribute('draggable', true);
		}
	});
	useEffect(() => {
		SetActive(props.activeBuff);
		setrefresh(true);
	});
	useEffect(() => {
		refresh &&
			setTimeout(() => {
				setrefresh(false);
			}, 200);
	}, [refresh]);
	return (
		<div
			className={`video-patrol-modal-vide-list-item ${actives.includes(props.index) ? 'active-item-video' : ''}`}
			ref={drag}
			id={props.device.devId}
		>
			<Tooltip title={props.device.devName}>{props.device.devName}</Tooltip>
		</div>
	);
};
export default DragItem;
