import React, { Component } from 'react';
import flvjs from 'flv.js';
import Hls from 'hls.js';
import { message } from 'antd';
import { Device } from '@/api';
import './index.less';
interface Props {
	id: string;
	type: string;
	startTime?: string;
	endTime?: string;
	onPlayError?: Function;
	onPlaySuccess?: Function;
	onDestroy?: Function;
	onPause?: Function;
	onPlay?: Function;
	onClick?: Function;
	onRef?: Function;
	videoRef?: string;
	autoPlay?: boolean;
	hasClose?: boolean;
	hasFullPage?: boolean;
	hasPause?: boolean;
	hasSetting?: boolean;
	hasCut?: boolean;
	hasNativeControl?: boolean;
	title?: string;
	onError?: Function;
}
interface State {
	playStatus: number;
	interval: any;
	startTime?: string;
	endTime?: string;
	id?: string;
}
class VideoPlayer extends Component<Props, State> {
	private player: any;
	private hls: any;
	private mousedown: any;
	constructor(props: Props) {
		super(props);
		this.state = {
			playStatus: 0,
			interval: null,
			startTime: '',
			endTime: '',
			id: props.id
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props, nextState: State) {
		nextProps.onRef && nextProps.onRef(this);
		const { type, startTime, endTime, autoPlay } = nextProps;
		if (nextProps.id !== this.props.id) {
			this.setState(
				{
					id: nextProps.id
				},
				() => {
					this.getVideoStreamByType(type, startTime, endTime);
				}
			);
		}
		// if (nextProps.type === 'hls') {
		// 	if (nextProps.startTime !== this.props.startTime || nextProps.endTime !== this.props.endTime) {
		// 		this.setState(
		// 			{
		// 				startTime,
		// 				endTime
		// 			},
		// 			() => {
		// 				if (autoPlay) {
		// 					this.getVideoStreamByType(type, startTime, endTime);
		// 				}
		// 			}
		// 		);
		// 	}
		// }
	}

	componentDidMount() {
		this.props.onRef && this.props.onRef(this);
		const { type, startTime, endTime } = this.props;
		if (this.props.autoPlay) {
			this.getVideoStreamByType(type, startTime, endTime);
		}
	}

	createPlayer = (url: string) => {
		if (this.props.type === 'flv') {
			this.createFlvPlayer(url);
		}
		if (this.props.type === 'hls') {
			this.createHlsPlayer(url);
		}
	};
	// 创建FLV播放器
	createFlvPlayer = (url: string) => {
		if (flvjs.isSupported()) {
			// @ts-ignore
			const element = this[this.props.videoRef];
			const player = flvjs.createPlayer(
				{
					type: 'flv',
					url: url,
					isLive: true,
					withCredentials: false,
					hasAudio: false,
					hasVideo: true,
					cors: true
				},
				{
					enableWorker: true,
					enableStashBuffer: false,
					fixAudioTimestampGap: false,
					autoCleanupSourceBuffer: true,
					lazyLoadMaxDuration: 3 * 60,
					seekType: 'range',
					autoCleanupMaxBackwardDuration: 2,
					autoCleanupMinBackwardDuration: 1
				}
			);
			player.attachMediaElement(element);
			this.setState({
				playStatus: 1
			});
			player.load();
			player.play();
			this.player = player;
			this.props.onPlay && this.props.onPlay();
			this.onVideoListener();
		}
	};
	// 创建HLS播放器
	createHlsPlayer = (url: string) => {
		if (Hls.isSupported()) {
			// @ts-ignore
			this.player = this[this.props.videoRef];
			if (this.player) {
				setTimeout(() => {
					this.hls = new Hls();
					this.hls.loadSource(url);
					this.hls.attachMedia(this.player);
					this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
						this.setState({
							playStatus: 1
						});
						this.hls.startLoad();
						this.player.play();
						if (typeof this.props.onPlay === 'function') {
							this.props.onPlay();
						}
						// if (!this.props.autoPlay) {
						//     setTimeout(() => {
						//         this.hls.stopLoad();
						//     }, 5000);
						// }
					});
					this.onVideoListener();
				}, 200);
			}
		}
	};
	// 监听视频状态 断线重连
	onVideoListener = () => {
		const { interval } = this.state;
		const { type, startTime, endTime } = this.props;
		// @ts-ignore
		const element = this[this.props.videoRef];
		if (!element) {
			return;
		}
		interval && clearInterval(interval);
		const intervalEle = setInterval(() => {
			const readyState = element.readyState;
			const networkState = element.networkState;
			const buffered = element.buffered;
			element.currentTime = buffered && buffered.length > 0 ? buffered.end(0) - 1 : element.currentTime;
			console.log(
				'网络状态: ' + networkState,
				'播放状态: ' + element.readyState,
				'播放器ID: ' + this.props.videoRef
			);
			if (readyState !== 4 || networkState !== 2) {
				console.log('播放异常，请稍后...');
				this.destroy();
				this.getVideoStreamByType(type, startTime, endTime);
			}
		}, 60 * 1000);
		this.setState({
			interval: intervalEle
		});
	};
	// 全屏
	fullPage = (e: any) => {
		this.stopBubble(e);
		// @ts-ignore
		const element = this[`${this.props.videoRef}Fa`];
		if (document.fullscreen) {
			document.exitFullscreen();
		} else if (element) {
			this.play();
			element.disablePictureInPicture = true;
			element.disableRemotePlayback = true;
			if (element.requestFullscreen) {
				element.requestFullscreen();
			} else if (element.mozRequestFullScreen) {
				element.mozRequestFullScreen();
			} else if (element.webkitRequestFullscreen) {
				element.webkitRequestFullscreen();
			} else if (element.msRequestFullscreen) {
				element.msRequestFullscreen();
			}
		}
	};
	// 处理播放器播放状态
	handlePlayerStatus = (e: any) => {
		this.stopBubble(e);
		const { playStatus } = this.state;
		if (playStatus === 0) {
			this.play();
		} else {
			this.pause();
		}
	};

	stopBubble = (e: any) => {
		if (e && e.stopPropagation) {
			e.stopPropagation();
		} else {
			e.cancelBubble = true;
		}
	};
	play = () => {
		if (this.player) {
			this.setState({
				playStatus: 1
			});
			this.player.play();
			if (typeof this.props.onPlay === 'function') {
				this.props.onPlay();
			}
		} else {
			const { type, startTime, endTime } = this.props;
			this.getVideoStreamByType(type, startTime, endTime);
		}
	};
	pause = () => {
		const { interval } = this.state;
		if (this.player) {
			this.setState({
				playStatus: 0
			});
			this.player.pause();
			interval && clearInterval(interval);
			if (typeof this.props.onPause === 'function') {
				this.props.onPause();
			}
		} else {
			console.error('视频组件挂载失败');
		}
	};
	// 销毁播放器
	destroy = () => {
		const { interval } = this.state;
		if (this.player) {
			if (this.props.type === 'hls') {
				if (this.hls) {
					this.hls.stopLoad();
					this.hls.detachMedia();
					this.hls.destroy();
				}
			}
			this.player.pause && this.player.pause();
			this.player.unload && this.player.unload();
			this.player.detachMediaElement && this.player.detachMediaElement();
			this.player.destroy && this.player.destroy();
			this.player = null;
			interval && clearInterval(interval);
			this.props.onDestroy && this.props.onDestroy();
		}
	};
	componentWillUnmount() {
		this.destroy();
	}
	onPlayerClick = () => {
		this.props.onClick && this.props.onClick(this.player);
	};

	getVideoStreamByType = (type: string, startTime?: any, endTime?: any) => {
		if (type === 'flv') {
			this.getFlvStream();
		}
		if (type === 'hls') {
			this.getHlsStream(startTime, endTime);
		}
	};

	getFlvStream = () => {
		const { id } = this.state;
		this.destroy();
		Device.getVideoStream({
			path: [id, '10002']
		})
			.then((res: any) => {
				if (res.code === 0) {
					if (res.data.wsUrl) {
						this.createPlayer(res.data.wsUrl);
					}
				} else {
					message.warning(res.msg);
					this.props.onError && this.props.onError();
				}
			})
			.catch((err: any) => {
				if (err.msg) {
					message.warning(err.msg);
				}
			});
	};

	getHlsStream = (startTime: any, endTime: any) => {
		const { id } = this.state;
		this.destroy();
		if (startTime && endTime) {
			Device.getVideoReplay({
				query: {
					devId: id,
					streamMode: '3',
					startTime,
					endTime
				}
			})
				.then((res: any) => {
					if (res.data) {
						this.props.onPlaySuccess && this.props.onPlaySuccess();
						this.createPlayer(res.data);
					} else {
						this.props.onPlayError && this.props.onPlayError();
						message.warning(res.msg);
					}
				})
				.catch((err: any) => {
					if (err.msg) {
						message.warning(err.msg);
					}
				});
		}
	};
	// 截图
	snapshot = (name?: string, hideDownLoad?: boolean) => {
		// @ts-ignore
		const video = this[this.props.videoRef];
		const canvas = document.createElement('canvas');
		canvas.width = video.offsetWidth;
		canvas.height = video.offsetHeight;
		const ctx = canvas.getContext('2d'); //设置canvas绘制2d图
		// @ts-ignore
		ctx.drawImage(video, 0, 0, canvas.width, canvas.height); //将video视频绘制到canvas中
		const images = canvas.toDataURL('image/png'); //canvas的api中的toDataURL()保存图像
		if (!hideDownLoad) {
			this.downloadImage(images, name);
		}
		return images;
	};
	// 下载图片
	downloadImage = (url: string, name?: string) => {
		const a = document.createElement('a');
		const fileName = name || 'image' + +new Date() + '.png';
		a.setAttribute('href', url);
		a.setAttribute('target', '_blank');
		a.setAttribute('id', 'vid');
		a.setAttribute('download', fileName);
		const canSupportDownload = 'download' in a;
		if (canSupportDownload) {
			document.body.appendChild(a);
			a.click();
		} else {
			alert('不支持下载');
			window.open(url);
		}
	};
	// 云台控制 鼠标按下上下左右移动
	handleMouseDown = (cmd: string) => {
		if (this.mousedown) {
			return;
		}
		const toSend = {
			cmd,
			devId: this.props.id,
			speed: 20
		};
		Device.videoControl({
			body: toSend
		})
			.then((res: any) => {})
			.catch((err: any) => {
				if (err.msg) {
					message.warning(err.msg);
				}
			});
		this.mousedown = true;
	};
	// 云台控制 鼠标抬起停止移动
	handleMouseUp = () => {
		this.mousedown = false;
		const toSend = {
			cmd: 'stop',
			devId: this.props.id,
			speed: 20
		};
		Device.videoControl({
			body: toSend
		})
			.then((res: any) => {})
			.catch((err: any) => {
				if (err.msg) {
					message.warning(err.msg);
				}
			});
	};

	renderCloud = () => {
		if (this.props.type === 'flv' && this.props.hasSetting) {
			return (
				<div className="video-player-control">
					<span
						className="control-top"
						onMouseDown={() => {
							this.handleMouseDown('up');
						}}
						onMouseUp={this.handleMouseUp}
					/>
					<span
						className="control-bottom"
						onMouseDown={() => {
							this.handleMouseDown('down');
						}}
						onMouseUp={this.handleMouseUp}
					/>
					<span
						className="control-left"
						onMouseDown={() => {
							this.handleMouseDown('left');
						}}
						onMouseUp={this.handleMouseUp}
					/>
					<span
						className="control-right"
						onMouseDown={() => {
							this.handleMouseDown('right');
						}}
						onMouseUp={this.handleMouseUp}
					/>
				</div>
			);
		} else {
			return null;
		}
	};
	renderVideoControl = () => {
		const { hasFullPage = true, hasCut = false, hasClose = false, title = '' } = this.props;
		return (
			<div className="video-ctrl">
				<div className="video-ctrl-inner">
					<div className="video-ctrl-left">
						{title && <span className="video-ctrl-title">{this.props.title}</span>}
					</div>
					<div className="video-ctrl-right">
						{hasCut && (
							<span
								onClick={() => {
									this.snapshot();
								}}
								className="video-ctrl-cut"
							></span>
						)}
						{hasFullPage && (
							<span onClick={this.fullPage} className="video-ctrl-max">
								<i className="iconfont icon-quanping" />
							</span>
						)}
						{hasClose && (
							<span onClick={this.destroy} className="video-ctrl-close">
								<i>&#xe626;</i>
							</span>
						)}
					</div>
				</div>
			</div>
		);
	};

	renderPauseMask = () => {
		const { hasPause = true } = this.props;
		return (
			<div className="pre-video-player-mask">
				<div
					className="pre-video-player-mask-inner"
					style={
						this.state.playStatus === 0
							? {
									backgroundColor: '#262626'
							  }
							: {
									backgroundColor: 'transparent'
							  }
					}
				>
					{hasPause && (
						<span
							onClick={this.handlePlayerStatus}
							className={`video-ctrl-pause ${this.state.playStatus === 0 ? 'play' : 'pause'}`}
						>
							{this.state.playStatus === 0 ? (
								<i className="iconfont icon-yinleicon_huaban1fuben10" />
							) : (
								<i className="iconfont icon-zantingtingzhi" />
							)}
						</span>
					)}
				</div>
			</div>
		);
	};

	render() {
		return (
			<div className={`video-player-container`} onClick={this.onPlayerClick}>
				<div className="video-player-container-bg" />
				<div className="video-player-container-content">
					<div
						className="video-player-container-content-inner"
						ref={(ref) => {
							// @ts-ignore
							return (this[`${this.props.videoRef}Fa`] = ref);
						}}
					>
						<video
							ref={(ref) => {
								// @ts-ignore
								return (this[this.props.videoRef] = ref);
							}}
							preload="metadata"
							muted={false}
							controls={this.props.hasNativeControl}
							className="video-player-view"
							style={{ height: '100%' }}
						/>
						{this.props.hasNativeControl ? null : this.renderPauseMask()}
						{this.props.hasNativeControl ? null : this.renderCloud()}
						{this.props.hasNativeControl ? null : this.renderVideoControl()}
					</div>
				</div>
			</div>
		);
	}
}

export default VideoPlayer;
