// eslint-disable-next-line import/no-anonymous-default-export
export default {
	backgroundColor: 'transparent',
	tooltip: {
		trigger: 'axis',
		backgroundColor: 'rgba(255,255,255,0.80)',
		borderColor: '#F1F1F1',
		borderWidth: 1,
		padding: [2, 4],
		textStyle: {
			color: '#666',
			fontSize: 12
		},
		extraCssText: 'box-shadow:0 1px 4px 0 rgba(0,0,0,0.20);border-radius:4px;',
		formatter: ''
	},
	grid: {
		top: '50px',
		left: '0',
		right: '0',
		bottom: '0',
		containLabel: true
	},
	xAxis: {
		axisLine: {
			show: false
		},
		axisTick: {
			show: false
		},
		axisLabel: {
			textStyle: {
				fontSize: 12,
				color: '#666'
			}
		},
		type: 'category',
		boundaryGap: true
	},
	yAxis: {
		axisLine: {
			show: false
		},
		axisTick: {
			show: false
		},
		axisLabel: {
			textStyle: {
				fontSize: 12,
				color: '#666'
			}
		},
		splitLine: {
			lineStyle: {
				color: '#f1f1f1'
			}
		},
		type: 'value'
		// boundaryGap: [0, '10%'],
	}
};
