const xDataArr: string[] = [];
for (let i = 0; i < 24; i++) {
	if (i < 10) {
		xDataArr.push('0' + i + ':00');
	} else {
		xDataArr.push(i + ':00');
	}
}

export const getOptionOne = (data: any[], arrData1: any) => {
	const maxNum = data?.length
		? data?.sort((a, b) => {
				return a - b;
		  })[data?.length - 1] + 4
		: 8 || 4;
	const dataText = [
		{
			name: '人员流量',
			type: 'line',
			smooth: false,

			symbolSize: 4,
			data: arrData1,
			itemStyle: {
				normal: {
					color: '#ca603f'
				}
			},
			lineStyle: {
				width: 1
			}
		}
		// {
		// 	name: '出园',
		// 	type: 'line',
		// 	smooth: false,
		// 	symbolSize: 4,
		// 	data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		// 	itemStyle: {
		// 		normal: {
		// 			color: 'rgba(3, 230, 3, 1)'
		// 		}
		// 	},
		// 	lineStyle: {
		// 		width: 1
		// 	}
		// }
	];

	return {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				// 坐标轴指示器，坐标轴触发有效
				type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
			}
		},
		legend: {
			x: 'center',
			bottom: '8%',
			textStyle: {
				color: '#c1cadf',
				fontSize: 12
			}
		},
		grid: {
			left: '6%',
			right: '7%',
			top: '5%',
			bottom: '20%',
			containLabel: true
		},
		toolbox: {
			show: true,
			orient: 'vertical',
			x: 'right',
			y: 'center'
		},
		xAxis: [
			{
				type: 'category',
				boundaryGap: false,
				data: xDataArr,
				axisLine: {
					onZero: false,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// minInterval: 4,
				// splitNumber: 4,
				// axisTick: {
				// 	// inside: true,
				// 	show: true,
				// 	length: 4
				// },
				axisLabel: {
					interval: 3,
					color: '#c1cadf',
					fontSize: '12'
				}
			}
		],
		yAxis: [
			{
				type: 'value',
				// min: 0,
				// max: maxNum,
				show: true,
				axisLine: {
					onZero: false,
					show: true,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// axisTick: {
				// 	inside: true
				// },

				axisLabel: {
					interval: 0,
					color: '#ffffff',
					fontSize: '12'
				},
				splitLine: {
					show: false
				}
			}
		],
		series: dataText
	};
};

export const getOptionTwo = (data1: number[], data2: number[], arrData1: any, arrData2: any) => {
	let data1Num: any;
	let data2Num: any;
	let date3Num = 800;
	if (data1?.length) {
		data1Num =
			data1?.sort((a, b) => {
				return b - a;
			})[0] || 0;
	}
	if (data2?.length) {
		data2Num =
			data2?.sort((a, b) => {
				return b - a;
			})[0] || 0;
	}

	if (data2Num !== 0 || data1Num !== 0) {
		if (!data1Num && !data2Num) {
			date3Num = 800;
		} else if (data1Num && !data2Num) {
			date3Num = data1Num;
		} else if (!data1Num && data2Num) {
			date3Num = data2Num;
		} else {
			date3Num = data2Num > data1Num ? data2Num || 800 : data1Num || 800;
		}
	}
	const dataText = [
		{
			name: '入园',
			type: 'line',
			smooth: false,

			symbolSize: 4,
			data: arrData1,
			itemStyle: {
				normal: {
					color: '#ca603f'
				}
			},
			lineStyle: {
				width: 1
			}
		},
		{
			name: '出园',
			type: 'line',
			smooth: false,
			symbolSize: 4,
			data: arrData2,
			itemStyle: {
				normal: {
					color: 'rgba(3, 230, 3, 1)'
				}
			},
			lineStyle: {
				width: 1
			}
		}
	];

	return {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				// 坐标轴指示器，坐标轴触发有效
				type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
			}
		},
		legend: {
			x: 'center',
			bottom: '8%',
			textStyle: {
				color: '#c1cadf',
				fontSize: 12
			}
		},
		grid: {
			left: '6%',
			right: '7%',
			top: '5%',
			bottom: '20%',
			containLabel: true
		},
		toolbox: {
			show: true,
			orient: 'vertical',
			x: 'right',
			y: 'center'
		},
		xAxis: [
			{
				type: 'category',
				boundaryGap: false,
				data: xDataArr,
				axisLine: {
					onZero: false,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// axisTick: {
				// 	inside: true
				// },
				axisLabel: {
					interval: 3,
					color: '#c1cadf',
					fontSize: '12'
				}
			}
		],
		yAxis: [
			{
				type: 'value',
				show: true,
				// min: data1?.length || data2?.length ? null : 0,
				// max: date3Num,
				axisLine: {
					onZero: false,
					show: true,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// axisTick: {
				// 	inside: true
				// },
				axisLabel: {
					interval: 0,
					color: '#ffffff',
					fontSize: '12'
				},
				splitLine: {
					show: false
				}
			}
		],
		series: dataText
	};
};
