import React, { Component, useState, useEffect, useMemo } from 'react';
import VideoPlayer from '@/components/videoPlayer';

import './index.less';
interface Props {
	id: string;
	relevanceVideo: any[];
}

const StatisticalTrends = (props: Props) => {
	const { relevanceVideo } = props;

	useEffect(() => {}, []);
	const videoList = useMemo(() => {
		if (relevanceVideo.length) {
			return relevanceVideo;
		}
		return [];
	}, [relevanceVideo]);
	return (
		<div className="con-video-info">
			{videoList.length ? (
				videoList.map((item: any, index: number) => {
					if (2 === item.status) {
						return (
							<div key={item.value} className="video-info-item no-online">
								<div className="name">{item.label || ''}</div>
								<div className="info">设备不在线</div>
							</div>
						);
					}
					return (
						<div key={item.value} className="video-info-item">
							<VideoPlayer
								id={item.value}
								videoRef={`${item.value}`}
								title={item.label}
								type="flv"
								autoPlay={true}
								onRef={(ref: any) => (item.videoPlayerRef = ref)}
							/>
						</div>
					);
				})
			) : (
				<div className="no-video">暂无视频源</div>
			)}
		</div>
	);
};

export default StatisticalTrends;
