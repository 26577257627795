import React, { Component, useState, useEffect } from 'react';
import img from '@/static/images/defaultImage.png';

import './index.less';
interface Props {
	info: any;
	iconType: 'carpark' | 'build' | 'public' | undefined;
	devDetailConfig: {
		baseInfos: { label: string; value: string }[];
	};
	devType: string;
}
const BasicInformation = (props: Props) => {
	const { info = {}, iconType, devType } = props;
	const {
		devDetailConfig: { baseInfos = [] }
	} = props;
	let type = '所属楼宇：';
	let location = info?.buildLocation;
	if (iconType === 'build') {
		type = '所属楼宇：';
		location = info?.buildLocation || info?.devLocation;
	} else if (iconType === 'carpark') {
		type = '所属车场：';
		location = info?.carParkName || info.devLocation;
	} else if (iconType === 'public') {
		type = '所属位置：';
		location = info?.buildLocation || info.devLocation;
	}

	return (
		<div className="basic_information">
			<p>
				<span>设备名称：</span>
				<span>{info.devName}</span>
			</p>
			<p>
				<span>设备类型：</span>
				<span>{info.devTypeName}</span>
			</p>
			<p>
				<span>所属区域：</span>
				<span>{info.areaName} </span>
			</p>
			<p>
				<span>{type}</span>
				<span>{location}</span>
			</p>
			<p>
				<span>详细地址：</span>
				<span>{info.address}</span>
			</p>
			{devType !== 'ELEVATOR' ? (
				<p>
					<span>在线状态：</span>
					<span>{['', '在线', '离线'][info.devStatus]}</span>
				</p>
			) : null}
			{baseInfos.map((item, index) => {
				return (
					<p key={index}>
						<span>{item.label}：</span>
						<span>{item.value}</span>
					</p>
				);
			})}
			<img src={info.image} alt="" />
		</div>
	);
};

export default BasicInformation;
