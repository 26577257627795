import './index.less';
import { Props } from './interface';
import React from 'react';
import pageConfig from './pageConfig';
const renderHeader = () => {
	return (
		<div className="dev-info-header">
			<div>
				<div>
					<div>设备数</div>
					<div>66</div>
				</div>
			</div>
			<div>
				<div>
					<div>在线</div>
					<div>33</div>
				</div>
				<div>
					<div>离线:</div>
					<div>33</div>
				</div>
			</div>
			<div>
				<div>
					<div>运行</div>
					<div>33</div>
				</div>
				<div>
					<div>停止:</div>
					<div>33</div>
				</div>
			</div>
		</div>
	);
};
const DevInfo = (props: Props) => {
	if (props.type === 'tableList') {
		return (
			<div className="dev-info-tab-box">
				<h1>更新时间： 2021-12-8 17:18:17</h1>
				{pageConfig.map((item: any, index: number) => {
					return (
						<div key={index} className="dev-info-tab-item">
							<h3>{item.title}</h3>
							<div>
								{item.info?.map((items: any, ind: number) => {
									return (
										<div key={ind}>
											<span>{items.label}:</span>
											<span>{items.value}</span>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		);
	}
	if (props.type === 'map') {
		return (
			<div className="dev-info-page">
				{renderHeader()}
				<div className="dev-info-map-item">
					<p style={{ color: 'rgba(0, 255, 255, 1)' }}>设备1</p>
					<p>
						<span>设备名称：</span>
						<span>1单元总泵</span>
					</p>
					<p>
						<span>设备类型：</span>
						<span>水泵</span>
					</p>
					<p>
						<span>在线状态：</span>
						<span style={{ color: '#36bd39' }}>在线</span>
					</p>
					<p>
						<span>运行状态：</span>
						<span>运行</span>
					</p>
					<p>
						<span>实时水压：</span>
						<span>1.2Mpa</span>
					</p>
					<p>
						<span>实时水温：</span>
						<span>23.3℃</span>
					</p>
					<p>
						<span>实时流量：</span>
						<span>2.3m³/h</span>
					</p>
				</div>
			</div>
		);
	}
	if (props.type === 'list') {
		return (
			<div className="dev-info-page">
				{renderHeader()}
				<div className="dev-info-table">
					<div className="th">
						<div className="td">点位名称</div>
						<div className="td">所在位置</div>
						<div className="td">在线状态</div>
						<div className="td">开启状态</div>
						<div className="td">累计用电量</div>
					</div>
					<div className="tb">
						<div className="tr">
							<div className="td">点位1</div>
							<div className="td">位置1</div>
							<div className="td" style={{ color: 'rgba(0, 255, 0, 1)' }}>
								在线
							</div>
							<div className="td" style={{ color: 'rgba(0, 255, 0, 1)' }}>
								开启
							</div>
							<div className="td">921.7</div>
						</div>
						<div className="tr">
							<div className="td">点位2</div>
							<div className="td">位置2</div>
							<div className="td" style={{ color: 'red' }}>
								离线
							</div>
							<div className="td" style={{ color: 'red' }}>
								关闭
							</div>
							<div className="td">921.7</div>
						</div>
					</div>
				</div>
				{/*<Pagination />*/}
			</div>
		);
	}
	if (props.type === 'loop') {
		return (
			<div className="dev-info-page">
				<div className="image">
					<img
						src="/security/v1.0.0/hik/getHikCrossRecordPath?aswSyscode=db269934-18b3-4ef2-9dca-20d8ad45ef39&picUri=/pic?=d81i5f4e*71fi549-093c58--118b38148ae23ifb5*=*d3d3i*s1d=i9p3t=pe*m6i14=99e2-63zef0s=5083d2"
						alt=""
					/>
					<div className="dot"></div>
				</div>
			</div>
		);
	}

	return <div>DevInfo</div>;
};
export default DevInfo;
