import React, { useState, memo, useRef } from 'react';
import { Backend } from '@/api';
import PageModule from '@/template/PageModule';
import LeftOne from './components/leftOne';
import RightOne from './components/rightOne';
import { storage } from '@/utils';

const Overview = () => {
	const [rightPageInfo, setRightPageInfo] = useState<any>({});
	const [leftPageInfo, setLeftPageInfo] = useState<any>({});
	const leftRef = useRef<any>(null);
	const rightRef = useRef<any>(null);

	const getData = async () => {
		const currentArea = storage.getSession('CURRENTAREA');
		const generalSituationRes: IotResponse = await Backend.screenData({
			query: {
				bigScreenLable: 'GENERAL_SITUATION',
				areaId: currentArea === 'all' ? '' : currentArea
			}
		});
		if (!generalSituationRes.code) {
			const {
				PARK_SECURITY = {},
				PEOPLE_CAR = {},
				SPACE_INFO = {},
				AREA_INFO = {},
				CAR_GROUP = {},
				PEOPLE = {},
				ALL_CARLOT_RATE = {}
			} = generalSituationRes?.data;
			const leftPageInfo = {
				SPACE_INFO,
				AREA_INFO,
				ALL_CARLOT_RATE
			};
			const rightPage = {
				PARK_SECURITY,
				parkPeopleVO: PEOPLE?.parkPeopleVO || {},
				parkCarVO: PEOPLE_CAR?.parkCarVO || {},
				CAR_GROUP,
				peopleFlowVO: PEOPLE?.peopleFlowVO || [],
				carFlowVO: PEOPLE?.carFlowVO || []
			};

			setLeftPageInfo((prev: any) => ({ ...prev, ...leftPageInfo }));
			setRightPageInfo((prev: any) => ({ ...prev, ...rightPage }));
		}
		const convenientTrafficRes: IotResponse = await Backend.screenData({
			query: {
				bigScreenLable: 'CONVENIENT_TRAFFIC',
				areaId: currentArea === 'all' ? '' : currentArea
			}
		});
		!convenientTrafficRes.code && setLeftPageInfo((prev: any) => ({ ...prev, ...convenientTrafficRes?.data }));
		!convenientTrafficRes.code && setRightPageInfo((prev: any) => ({ ...prev, ...convenientTrafficRes?.data }));
	};
	const init = () => {
		getData();
		leftRef && leftRef?.current?.initThree();
		rightRef && rightRef?.current?.initRightThree();
	};
	return (
		<PageModule pageInit={init}>
			<div className={'leftView'} key="left">
				<LeftOne pageInfo={leftPageInfo} ref={leftRef}></LeftOne>
			</div>
			<div className={'rightView'} key="right">
				<RightOne pageInfo={rightPageInfo} ref={rightRef}></RightOne>
			</div>
		</PageModule>
	);
};

export default memo(Overview);
