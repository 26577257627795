import React, { Component } from 'react';
import classes from './index.module.css';
import * as redux from 'react-redux';
import { Props, State } from './interface';
import { Backend as deviceApi, BaseData } from '@/api';
import PageModule from '@/template/PageModule';
import DeviceList, { iconList } from './deviceList';
import DeviceCheck from './deviceCheck';
import Statistics from './statistics';
import DeviceEvent from './deviceEvent';
import FireAlarmTrend from './fireAlarmTrend';
import TodyFireIncident from './todyFireIncident';
import moment from 'moment';
import { getLastSixMon, storage } from '@/utils';
import { IfireAlarmTrendData } from './interface';
import { Checkbox, Modal } from 'antd';
import ModalPOI from '@/components/ModalPOI';
import DeviceDetail from './deviceDetail';
import './index.less';

const { connect } = redux;
const dateArr = getLastSixMon();

class EquipmentControl extends Component<Props, State> {
	static getDerivedStateFromProps(nextProps: any, prevState: State) {
		const { GMapInstance } = window;

		if (nextProps.currentArea !== prevState.currentArea) {
			GMapInstance?.removeMarker('build2');
			GMapInstance?.removeMarker('carpark2');
			GMapInstance?.removeMarker('build1');
			GMapInstance?.removeMarker('carpark1');
			return {
				// devTypeSelect: [],
				currentArea: nextProps.currentArea
			};
		} else {
			return {
				currentArea: nextProps.currentArea
			};
		}
	}

	constructor(props: Props) {
		super(props);
		this.state = {
			deviceCheckInfo: [],
			deviceListInfo: [],
			eventTypesTime: '',
			eventTypes: {
				faceAlarm: 2,
				modelAlarm: 3,
				vehicleAlarm: 4
			},
			statisticsNumber: {},
			eventProcessingData: [
				{ name: '', value: 0 },
				{ name: '', value: 0 }
			],
			todyFireIncidentData: {},
			time: dateArr[dateArr.length - 1] + ' 23:59:59|' + dateArr[0] + ' 00:00:00',
			time1: dateArr[dateArr.length - 1] + ' 23:59:59|' + dateArr[0] + ' 00:00:00',
			repairTime: '|',
			fireAlarmTrendData: {
				finished: [],
				all: []
			},
			fireAlarmTrendData1: {
				finished: [],
				all: []
			},
			devTypeList: [],
			devTypeSelect: [],
			devTypeName: undefined,
			showDeviceDetail: false,
			detailDevId: '', //423083125980008307
			currentArea: props.currentArea,
			iconType: undefined
		};
	}

	shouldComponentUpdate(nextProps: any, prevState: any): any {
		if (nextProps.currentArea !== this.props.currentArea && prevState.devTypeSelect?.length) {
			this.setBuildIcon(
				nextProps.currentArea === 'all' ? '' : nextProps.currentArea,
				'build2',
				(e: any) => {
					// console.log(e);
					if (e.longitude && e.latitude) {
						return {
							longitude: e.longitude,
							latitude: e.latitude,
							id: String(e.id),
							icon: `<img src="${require('../../static/images/buildpoiicon.png').default}" />`,
							name: e.buildName,
							type: 'build2'
						};
					}
				},
				this.state.devTypeSelect[0],
				true
			);
		}
		return true;
	}

	componentWillUnmount() {
		const { GMapInstance } = window;
		GMapInstance?.removeMarker('build2');
		GMapInstance?.removeMarker('carpark2');
		GMapInstance?.removeMarker('build1');
		GMapInstance?.removeMarker('carpark1');
	}
	getScreenData = () => {
		const { currentArea } = this.props;
		deviceApi
			.screenData({
				query: {
					bigScreenLable: 'PARK_DEV',
					areaId: currentArea === 'all' ? '' : currentArea
				}
			})
			.then((res: IotResponse) => {
				if (res.code === 0) {
					const { getDevNumByOnlineStatus = [], getDevNumByType = [] } = res?.data;
					this.setState({
						deviceCheckInfo: getDevNumByOnlineStatus,
						deviceListInfo: getDevNumByType
					});
				}
			})
			.catch((err: any) => {
				throw err;
			});
	};
	// 今日设备事件
	getTodyFireIncident = () => {
		const { currentArea } = this.props;
		const loginObj: any = storage.getSession('login') || {};
		const timestamp: number = Date.parse(new Date() as any);
		const start = moment(timestamp).format('YYYY-MM-DD 00:00:00');
		const end = moment(timestamp).format('YYYY-MM-DD 23:59:59');
		const obj: any = {
			pageNum: 1,
			pageSize: 500,
			tenantId: loginObj.companyId,
			// companyId: loginObj.companyId,
			startAfter: start,
			// startAfter: '2021-07-26 00:00:00',
			startBefore: end,
			// categroy: 'devWarn'
			inVariables: { eventType: ['devWarn', 'drop_dev'] }
		};
		if (currentArea !== 'all') {
			obj.andLikeVariables = {
				areaId: currentArea
			};
		} else {
			obj.andLikeVariables = {};
		}

		deviceApi
			.getList({
				body: {
					...obj
				}
			})
			.then((res: IotResponse) => {
				if (res.code === 0) {
					this.setState({
						todyFireIncidentData: res?.data
					});
				}
			})
			.catch((err: any) => {
				throw err;
			});
	};
	// 设备预警趋势
	getFireAlarmTrend = () => {
		const { currentArea } = this.props;
		const { time } = this.state;

		deviceApi
			.eventTrend({
				body: {
					areaId: currentArea === 'all' ? '' : currentArea,
					eventType: 'DEV_WARN',
					endTime: time?.split('|')[1] || '',
					startTime: time?.split('|')[0] || ''
				}
			})
			.then((res: IotResponse) => {
				if (res.code === 0) {
					this.setState({
						fireAlarmTrendData: {
							finished: res?.data.FINISHED,
							all: res?.data.ALL
						} as IfireAlarmTrendData
					});
				}
			})
			.catch((err: any) => {
				throw err;
			});
	};
	//保养工单月度趋势
	getFireAlarmTrend1 = () => {
		const { currentArea } = this.props;
		const { time1 } = this.state;

		deviceApi
			.statisticByTime({
				body: {
					areaId: currentArea === 'all' ? '' : currentArea,
					eventType: 'DEV_MAINTENANCE',
					endTime: time1?.split('|')[1]?.split(' ')[0] || '',
					startTime: time1?.split('|')[0]?.split(' ')[0] || ''
				}
			})
			.then((res: IotResponse) => {
				if (res.code === 0) {
					this.setState({
						fireAlarmTrendData1: {
							finished: res?.data || [],
							all: res?.data || []
						} as IfireAlarmTrendData
					});
				}
			})
			.catch((err: any) => {
				throw err;
			});
	};

	init = () => {
		this.getScreenData();
		this.getEventType(); // 设备事件类型
		this.getTodyFireIncident(); // 今日设备事件
		this.getFireAlarmTrend(); // 设备预警趋势
		this.getRepairStatistics(); // 设备维修统计
		this.getParkDevType(); //获取设备类型
		this.getFireAlarmTrend1(); // 设备保养维修单
	};

	fireAlarmTrendOnChange = (value: string, value1: string) => {
		this.setState(
			{
				time: value + '|' + value1
			},
			() => {
				this.getFireAlarmTrend();
			}
		);
	};

	fireAlarmTrendOnChange1 = (value: string, value1: string) => {
		this.setState(
			{
				time1: value + '|' + value1
			},
			() => {
				this.getFireAlarmTrend1();
			}
		);
	};

	eventTypesOnChange = (value: string, value1: string) => {
		this.setState(
			{
				eventTypesTime: (value || '') + '|' + (value1 || '')
			},
			() => {
				this.getEventType();
			}
		);
	};
	// 设备事件类型
	getEventType = () => {
		const { currentArea } = this.props;
		const { eventTypesTime } = this.state;
		const [start = '', end = ''] = eventTypesTime?.split('|');
		deviceApi
			.eventNum({
				body: {
					areaId: currentArea === 'all' ? '' : currentArea,
					eventType: 'DEV_WARN',
					endTime: end,
					startTime: start
				}
			})
			.then((res: IotResponse) => {
				if (res.code === 0) {
					this.setState({
						eventTypes: res?.data
					});
				}
			})
			.catch((err: any) => {
				throw err;
			});
	};
	repairStatisticsOnChange = (value: string, value1: string) => {
		this.setState(
			{
				repairTime: value + '|' + value1
			},
			() => {
				this.getRepairStatistics();
			}
		);
	};
	// 保养维修统计
	getRepairStatistics = () => {
		const { currentArea } = this.props;
		const { repairTime } = this.state;
		const [start = '', end = ''] = repairTime?.split('|');
		deviceApi
			.eventNum({
				body: {
					areaId: currentArea === 'all' ? '' : currentArea,
					eventType: 'DEV_MAINTENANCE',
					endTime: end,
					startTime: start
				}
			})
			.then((res: IotResponse) => {
				if (res.code === 0) {
					this.setState({ statisticsNumber: res.data });
				}
			})
			.catch((err: any) => {
				throw err;
			});
		deviceApi
			.hangInTheAir({
				body: {
					areaId: currentArea === 'all' ? '' : currentArea,
					eventType: 'DEV_MAINTENANCE',
					endTime: end,
					startTime: start
				}
			})
			.then((res: IotResponse) => {
				if (res.code === 0) {
					this.setState({ eventProcessingData: res.data });
				}
			})
			.catch((err: any) => {
				throw err;
			});
	};
	// 获取设备类型
	getParkDevType = () => {
		BaseData.getParkDevType({
			path: ['BIGSCREENDEV']
		})
			.then((res: any) => {
				if (res.code === 0) {
					this.setState({
						devTypeList: res.data
					});
				}
			})
			.catch((err: any) => {
				throw err;
			});
	};

	// 照明信息广播打点
	setIcon = (area: string, type: string, pip: Function, videoType: string) => {
		const { currentArea } = this.props;
		const { GMapInstance } = window;
		BaseData.getDeviceListNoPage({
			body: {
				type: videoType,
				parentId: currentArea === 'all' ? '' : currentArea,
				videoType: 'PUBLIC'
			}
		}).then((res: any) => {
			if (res.code === 0) {
				const resData = res.data;
				const result: any[] = [];
				resData?.map((item: any) => {
					const temp = pip(item);
					if (temp?.longitude && temp?.latitude && temp?.latitude?.length && temp?.longitude?.length) {
						temp.listener = {
							click: () => {
								this.setState({
									iconType: 'public'
								});
								this.pageRef.toggleView('showRightView', true);
								setTimeout(() => {
									this.setState({
										detailDevId: item.devId,
										showDeviceDetail: true
									});
								}, 500);
							}
						};

						result.push(temp);
						return item;
					}
				});
				GMapInstance?.drawMarkers(result);
			}
		});
	};

	// 楼宇车场打点
	setBuildIcon = (area: string, type: string, pip: Function, videoType: string, displayMore?: boolean) => {
		const { currentArea } = this.props;
		const { GMapInstance } = window;

		GMapInstance?.removeMarker('build1');
		GMapInstance?.removeMarker('carpark1');

		if (displayMore && (videoType === 'LIGHT' || videoType === 'INFORMATION' || videoType === 'RADIO')) {
			this.setIcon(
				area,
				type,
				(e: any) => {
					// console.log(e);
					if (e.longitude && e.latitude && e.latitude?.length && e.longitude?.length) {
						return {
							longitude: e.longitude,
							latitude: e.latitude,
							id: String(e.devId),
							icon: `<img src="${require('../../static/images/' + videoType + '.png').default}" />`,
							name: e.devName,
							type: 'build2'
						};
					}
				},
				videoType
			);
			// return;
		}

		deviceApi
			.getBuildsByArea({
				query: {
					areaId: area,
					type: videoType
				}
			})
			.then((res: IotResponse) => {
				if (res.code === 0) {
					const data = res.data;

					const result: any[] = [];
					data?.buildingDetails?.map((item: any) => {
						const temp = pip(item);

						// item.icon=`<img src="${require('../../static/images/' + videoType + '.png').default}" />`
						temp.listener = {
							click: () => {
								BaseData.getScreenBuildInfo({
									path: [item.id]
								}).then((res: IotResponse) => {
									if (res.code === 0) {
										const resData = res.data;
										const data: PoiModalData = {
											title: item.buildAreaName + '-' + item.buildName,
											defaultData: [
												{
													label: '楼宇名称',
													value: resData.buildName
												},
												{
													label: '楼宇类型',
													value: resData.buildTypeName
												},
												{
													label: '占地面积',
													value: resData.buildMeasure + '/㎡'
												},
												{
													label: '单元数',
													value: resData.unitNums || ''
												},
												{
													label: '房屋数',
													value: resData.houseNums || ''
												},
												{
													label: '企业数',
													value: resData.businessNums
												},
												// {
												// 	label: '入住人数',
												// 	value: resData.occupantNums
												// },
												{
													label: '楼宇电耗',
													value: resData.energyNums
												},
												{
													label: '图片',
													value: ''
												}
											]
										};
										Modal.info({
											className: 'modal-poi-view',
											content: <ModalPOI dataSource={data} />,
											getContainer: false,
											mask: false,
											maskClosable: true,
											width: '420rem',
											onCancel: () => {
												GMapInstance.map?.setCenter(GMapInstance.center);
											}
										});
									}
								});
							}
						};

						result.push(temp);
						if (item?.total && displayMore) {
							result.push({
								longitude: item.longitude,
								latitude: item.latitude,
								id: String(item.id + 1223315),
								icon: `<span class="marker_total"><span>${item?.total || 0}</span></span>`,
								type: 'build1',
								zIndex: 200,
								listener: {
									click: () => {
										if (!item?.total) {
											return null;
										}
										this.setState({
											iconType: 'build'
										});
										BaseData.getDeviceListNoPage({
											body: {
												type: videoType,
												parentId: currentArea === 'all' ? '' : currentArea,
												build: item.id
											}
										}).then((res: any) => {
											if (res.code === 0) {
												const resData = res.data;
												Modal.info({
													className: 'modal-poi-dev-list',
													content: <span> {this.renderDevList(resData)}</span>,
													getContainer: this.pageDomRef,
													mask: false,
													maskClosable: false,
													width: '230rem',
													onCancel: () => {
														this.setState({
															showDeviceDetail: false,
															iconType: 'build'
														});
														this.pageRef.toggleView('showRightView', false);
														GMapInstance.map?.setCenter(GMapInstance.center);
													}
												});
											}
										});
									}
								}
							});
						}

						return item;
					});

					data?.carParkDetails?.map((item: any) => {
						const temp: any = {
							longitude: item.longitude,
							latitude: item.latitude,
							id: String(item.id),
							icon: `<img src="${require('@/static/images/carparkpoiicon.png').default}" />`,
							name: item.parkName,
							type: 'carpark2'
						};
						temp.listener = {
							click: () => {
								BaseData.getScreenCarParkInfo({
									path: [item.id]
								}).then((res: IotResponse) => {
									if (res.code === 0) {
										const resData = res.data;
										const data: PoiModalData = {
											title: item.areaName + '-' + item.parkName,
											defaultData: [
												{
													label: '车场名称',
													value: item.parkName
												},
												{
													label: '所属区域',
													value: item.areaName
												},
												{
													label: '详细地址',
													value: resData.parkAddress
												},
												{
													label: '责任人',
													value: resData.parkPersonName
												},
												{
													label: '联系电话',
													value: resData.telephone
												},
												{
													label: '车位总数',
													value: resData.parkSpaceNum || 0
												},
												{
													label: '充电车位',
													value: resData.underParkSpaceNum || 0
												},
												{
													label: '当前停车数',
													value: '1'
												},
												{
													label: '图片',
													value: ''
												}
											]
										};
										Modal.info({
											className: 'modal-poi-view',
											content: <ModalPOI dataSource={data} />,
											getContainer: false,
											mask: false,
											maskClosable: true,
											width: '420rem',
											onCancel: () => {
												GMapInstance.map?.setCenter(GMapInstance.center);
											}
										});
									}
								});
							}
						};

						result.push(temp);
						if (item?.total && displayMore) {
							result.push({
								longitude: item.longitude,
								latitude: item.latitude,
								id: String(item.id + 66655811),
								icon: `<span class="marker_total"><span>${item?.total || 0}</span></span>`,
								name: item.parkName,
								type: 'carpark1',
								zIndex: 200,
								listener: {
									click: () => {
										if (!item?.total) {
											return null;
										}
										this.setState({
											iconType: 'carpark'
										});
										BaseData.getDeviceListNoPage({
											body: {
												type: videoType,
												parentId: currentArea === 'all' ? '' : currentArea,
												carpark: item.id
											}
										}).then((res: any) => {
											if (res.code === 0) {
												const resData = res.data;
												Modal.info({
													className: 'modal-poi-dev-list',
													content: <span> {this.renderDevList(resData)}</span>,
													getContainer: this.pageDomRef,
													mask: false,
													maskClosable: false,
													width: '230rem',
													onCancel: () => {
														this.setState({
															showDeviceDetail: false,
															iconType: 'carpark'
														});
														this.pageRef.toggleView('showRightView', false);
														GMapInstance.map?.setCenter(GMapInstance.center);
													}
												});
											}
										});
									}
								}
							});
						}
						return item;
					});

					GMapInstance?.drawMarkers(result);
				}
			});
	};

	// 渲染设备列表
	renderDevList = (
		data: any = [
			// {
			// 	devName: 'devName',
			// 	devType: 'DRAINAGE',
			// 	devStatus: 2,
			// 	id: '423083125980008307'
			// },
			// {
			// 	devName: 'devName'
			// }
		]
	) => {
		return data?.map((item: any, index: number) => {
			return (
				<div
					key={index}
					className="dev-list-item"
					onClick={() => {
						this.pageRef.toggleView('showRightView', true);
						setTimeout(() => {
							this.setState({
								detailDevId: item.devId,
								showDeviceDetail: true
							});
						}, 500);
					}}
				>
					<img src={iconList[item.devType]} className={item.devStatus !== 1 ? 'gary-class' : ''} alt="" />
					<div className="name">{item.devName}</div>
				</div>
			);
		});
	};
	pageRef: any;
	rightRef: any;
	pageDomRef: any;
	render() {
		const {
			deviceCheckInfo,
			deviceListInfo,
			statisticsNumber = {},
			eventProcessingData,
			todyFireIncidentData,
			fireAlarmTrendData,
			fireAlarmTrendData1,
			time,
			time1,
			eventTypes,
			devTypeList,
			devTypeSelect,
			iconType
		} = this.state;
		return (
			<>
				<PageModule
					pageInit={this.init}
					onRef={(ref: any) => (this.pageRef = ref)}
					ref={(ref: any) => (this.pageDomRef = ref)}
				>
					<div className={'leftView'} key="left">
						<div style={{ height: '38%' }}>
							<DeviceList data={deviceListInfo} />
						</div>
						<div style={{ height: '24%', marginTop: '20rem' }}>
							<DeviceCheck data={deviceCheckInfo}></DeviceCheck>
						</div>
						<div style={{ height: '31%', marginTop: '20rem' }}>
							<Statistics
								data={eventProcessingData}
								statisticsNumber={statisticsNumber}
								onChange={this.repairStatisticsOnChange}
							/>
						</div>

						{devTypeList?.length ? (
							<div className="device-check_box">
								<Checkbox.Group value={devTypeSelect}>
									{devTypeList?.map((item: any) => {
										return (
											<Checkbox
												key={item.type}
												value={item.type}
												onChange={(e: any) => {
													let displayMore = true;
													if (devTypeSelect.includes(e.target.value)) {
														this.setState(
															{
																devTypeSelect: [],
																devTypeName: ''
															},
															() => {
																const { GMapInstance } = window;
																GMapInstance?.removeMarker('build2');
																GMapInstance?.removeMarker('carpark2');
																GMapInstance?.removeMarker('build1');
																GMapInstance?.removeMarker('carpark1');
															}
														);

														displayMore = false;
													} else {
														this.setState({
															devTypeSelect: [e.target.value],
															devTypeName: devTypeList?.find((items: any) => {
																return items.type === e.target.value;
															}).name
														});
														displayMore = true;
														this.setBuildIcon(
															this.props.currentArea === 'all'
																? ''
																: this.props.currentArea,
															'build2',
															(e: any) => {
																// console.log(e);
																if (e.longitude && e.latitude) {
																	return {
																		longitude: e.longitude,
																		latitude: e.latitude,
																		id: String(e.id),
																		icon: `<img src="${
																			require('../../static/images/buildpoiicon.png')
																				.default
																		}" />`,
																		name: e.buildName,
																		type: 'build2'
																	};
																}
															},
															e.target.value,
															displayMore
														);
													}
												}}
											>
												{item.name}
											</Checkbox>
										);
									})}
								</Checkbox.Group>
							</div>
						) : null}
					</div>
					<div className={'rightView'} key="right" ref={(ref) => (this.rightRef = ref)}>
						<div style={{ height: '31%' }}>
							<DeviceEvent data={eventTypes} onChange={this.eventTypesOnChange} />
						</div>
						<div style={{ height: '32%', marginTop: '20rem' }}>
							<FireAlarmTrend
								time={time}
								data={fireAlarmTrendData}
								onChange={this.fireAlarmTrendOnChange}
							/>
						</div>
						<div style={{ height: '30%', marginTop: '20rem' }}>
							<TodyFireIncident
								time={time1}
								data={fireAlarmTrendData1}
								onChange={this.fireAlarmTrendOnChange1}
							/>
						</div>
					</div>
				</PageModule>
				<DeviceDetail
					name={this.state?.devTypeName}
					show={this.state.showDeviceDetail}
					devId={this.state.detailDevId}
					iconType={iconType}
				/>
			</>
		);
	}
}

const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: Function) => {
	return {
		dispatch
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(EquipmentControl);
