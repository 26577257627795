import React, { Component } from 'react';

import * as redux from 'react-redux';
import { Props, State } from './interface';
import classes from './index.module.css';
import '../../../static/css/base.css';
import Card from '@/components/Card';
import VideoPlayer from '@/components/videoPlayer';

const { mediaBox } = classes;
const { connect } = redux;

class Monitoring extends Component<Props, State> {
	videoBody: any;
	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		return null;
	}

	constructor(props: Props) {
		super(props);
		this.state = {
			isFullscreen: false
		};
	}

	componentDidMount(): void {
		document.addEventListener('fullscreenchange', this.handler);
	}
	componentWillUnmount(): void {
		document.removeEventListener('fullscreenchange', this.handler);
	}
	handler = () => {
		const isFullscreen = document.fullscreenElement !== null;
		this.setState({
			isFullscreen
		});
	};

	changeView = () => {
		this.props?.changeView();
	};
	render() {
		const { tableData } = this.props;
		const { isFullscreen } = this.state;
		return (
			<div className={mediaBox}>
				<Card titleName="重要监控" style={cardStyle}>
					<div style={selectDivStyle} onClick={() => this.changeView()}>
						换一批
					</div>
					<div
						className={`${classes.videoInfoBody} videoInfoContent`}
						ref={(ref: any) => (this.videoBody = ref)}
					>
						<div className={classes.videoList}>
							{tableData?.length > 0 &&
								tableData.map((item: AnyObject, index: number) => {
									if (index >= 3) {
										return null;
									}
									// @ts-ignore
									return (
										<div className={classes.videoListItem} key={index}>
											<div
												className={`${classes.videoListItemInner} ${
													isFullscreen === true ? 'fullscreen' : ''
												}`}
											>
												{item.id ? (
													<>
														<span
															className={`${classes.videoOnline} ${
																item.devStatus === 1 ? classes.online : classes.offline
															}`}
														/>
														<VideoPlayer
															id={item.id}
															videoRef={`${item.id}`}
															title={item.name}
															type="flv"
															autoPlay={true}
															onRef={(ref: any) => (item.videoPlayerRef = ref)}
														/>
													</>
												) : (
													<div className={classes.emptyVideoItem}>暂无视频源</div>
												)}
											</div>
										</div>
									);
								})}
						</div>
					</div>
				</Card>
			</div>
		);
	}
}

const cardStyle: any = {
	width: '100%',
	height: '100%'
};
const selectDivStyle: any = {
	position: 'absolute',
	top: '-28.4rem',
	right: '1rem',
	color: '#ffffff',
	cursor: 'pointer'
};

const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: Function) => {
	return {
		dispatch
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Monitoring);
