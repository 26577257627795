import React, { Component } from 'react';

import * as redux from 'react-redux';
import { Props, State } from './interface';
import classes from './index.module.css';
import Pie3D from '@/components/Charts/pie3D/index';
import Card from '@/components/Card';
import DataSelect from '@/components/DataSelect/index';
import { optionList } from './selectOption';

const { mediaBox, box, boxTitle, right, bottom } = classes;
const { connect } = redux;

class DeviceEvent extends Component<Props, State> {
	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		return null;
	}

	constructor(props: Props) {
		super(props);
		this.state = {};
	}

	componentDidMount(): void {}

	timeChange = (startDate: any, endDate: any) => {
		this.props.onChange && this.props.onChange(startDate, endDate);
	};
	render() {
		const { data } = this.props;
		const Pie3dData = [
			{
				name: '设备预警任务',
				y: data?.devWarn || 0
			},
			{
				name: '状态预警任务',
				y: data?.drop_dev || 0
			},
			{
				name: '设备事件',
				y: data?.device_report || 0
			}
		];
		const newOption = {
			chart: {
				type: 'pie',
				marginTop: -10,
				marginRight: -10,
				height: '130rem', // width: '220rem',
				backgroundColor: 'transparent',
				options3d: {
					enabled: true,
					alpha: 40,
					beta: 10
				}
			}
		};
		return (
			<Card titleName="设备事件统计" style={cardStyle}>
				<div style={selectDivStyle}>
					<DataSelect dataOption={optionList} defaultOption="" onChange={this.timeChange}></DataSelect>
				</div>
				<div className={box}>
					<div className={boxTitle}>
						<span>设备任务总数：</span>
						<span>{data?.devWarn + data?.drop_dev + data?.device_report || 0}</span>
					</div>
					<div className={bottom}>
						<div style={pie3dStyle}>
							<Pie3D
								dataSource={Pie3dData}
								title="预警信息"
								colors={['#50A6EF', '#FDA400', '#5AD049']}
								newOption={newOption}
							></Pie3D>
						</div>
						<div className={right}>
							<div>
								<span>设备预警任务</span>
								<span>{data?.devWarn || 0}</span>
							</div>
							<div>
								<span>状态预警任务</span>
								<span>{data?.drop_dev || 0}</span>
							</div>
							<div>
								<span>设备事件</span>
								<span>{data?.device_report || 0}</span>
							</div>
						</div>
					</div>
				</div>
			</Card>
		);
	}
}

const cardStyle: any = {
	width: '100%',
	height: '100%'
};
const selectDivStyle: any = {
	position: 'absolute',
	top: '-26rem',
	right: '1rem'
};

const pie3dStyle: any = {
	width: '185rem',
	height: '140rem'
};

const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: Function) => {
	return {
		dispatch
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(DeviceEvent);
