import React, { Component, useState, useEffect } from 'react';
import { Tabs } from 'antd';
import './index.less';
import BasicInformation from './basicInformation';
import StatisticalTrends from './statisticalTrends';
import { BaseData } from '@/api';
import { typeMap } from './pageConfig';
import DevInfo from '@/pages/device/deviceDetail/devInfo';
import VideoInfo from '@/pages/device/deviceDetail/videoInfo';
const { TabPane } = Tabs;
interface Props {
	name: string;
	show: boolean;
	devId: string;
	iconType: 'carpark' | 'build' | 'public' | undefined;
}

const DeviceDetail = (props: Props) => {
	const [devDetail, setDevDetail] = useState<any>({});
	const [devDetailConfig, setDevDetailConfig] = useState<{
		baseInfos: { label: string; value: string }[];
		devInfoPageType: 'list' | 'map' | 'loop';
	}>({
		baseInfos: [],
		devInfoPageType: 'list'
	});
	const [tabIsHide, setTabIsHide] = useState<string[]>([]);
	const [activeKey, setActiveKey] = useState<string>('1');
	const [devType, setDevType] = useState<string>('default');
	const { show } = props;
	useEffect(() => {
		if (!props.devId) {
			return;
		}
		BaseData.getDevDetail({
			path: [props.devId]
		}).then((res: any) => {
			if (res.code === 0) {
				setDevDetail({ ...res.data });
				const { devType = 'default' } = res.data || {};
				setDevType(devType);
				const { hideTabs = [] } = typeMap[devType];
				setTabIsHide([...hideTabs]);
				setDevDetailConfig(typeMap[devType]);
				setActiveKey('1');
			}
		});
	}, [props.devId]);

	const onTabChange = (activeKey: string) => {
		setActiveKey(activeKey);
	};
	return (
		<div className={`device_detail ${props.show ? 'show-device-detail' : ''}`}>
			<div className="title-name">{props.name}</div>
			<Tabs activeKey={activeKey} onChange={onTabChange}>
				{tabIsHide.includes('1') ? null : (
					<TabPane tab="基础信息" key="1">
						<BasicInformation
							info={devDetail}
							devDetailConfig={devDetailConfig}
							iconType={props?.iconType}
							devType={devType}
						/>
					</TabPane>
				)}
				{tabIsHide.includes('2') ? null : (
					<TabPane tab="设备信息" key="2">
						<DevInfo type={devDetailConfig.devInfoPageType} />
					</TabPane>
				)}
				{tabIsHide.includes('3') ? null : (
					<TabPane tab="设备图纸" key="3">
						<div className="device-image"></div>
					</TabPane>
				)}
				{tabIsHide.includes('4') ? null : (
					<TabPane tab="视频信息" key="4">
						<VideoInfo id={devDetail?.devId} relevanceVideo={devDetail?.relevanceVideo} />
					</TabPane>
				)}
				{tabIsHide.includes('5') ? null : (
					<TabPane tab="趋势统计" key="5">
						<StatisticalTrends type={devDetailConfig.devInfoPageType} />
					</TabPane>
				)}
			</Tabs>
		</div>
	);
};

export default DeviceDetail;
