import React, { Component } from 'react';

import * as redux from 'react-redux';
import { Props, State } from './interface';
import classes from './index.module.css';
import Card from '@/components/Card';
import Bar from '@/components/Charts/Bar/index';
import DataSelect from '@/components/DataSelect/index';
import { getOption } from './lineOption';
import { getdataList } from '@/utils';
import { optionList } from './selectOption';

const { box, total, tableHeard, table, red, green, longView } = classes;
const { connect } = redux;
class Security extends Component<any, State> {
	timer: any;
	timerInter: any;
	speed: number;
	delay: number;
	scrollNumber: number;
	stop: boolean;
	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		return null;
	}

	constructor(props: Props) {
		super(props);
		this.speed = 20;
		this.delay = 1000;
		this.scrollNumber = 5;
		this.stop = true;
		this.state = {
			top: 0
		};
	}

	componentDidMount(): void {}
	handleData = () => {
		const { data, time } = this.props;
		const start = time.split('|')[0];
		const end = time.split('|')[1];
		const data1: number[] = [];
		const data2: number[] = [];

		data?.finished?.map((item: any, index: any) => {
			data1.push(item.value);
		});
		data?.all?.map((item: any, index: any) => {
			data2.push(item.value);
		});

		return getOption(data, getdataList(start, end));
	};

	timeChange = (startDate: string, endDate: string) => {
		this.props.onChange && this.props.onChange(startDate, endDate);
	};
	render() {
		const { data } = this.props;
		const { top } = this.state;
		return (
			<Card titleName="保养工单月度趋势" style={{ height: '100%', width: '100%' }}>
				<div
					style={{
						position: 'absolute',
						top: '-26rem',
						right: '1rem'
					}}
				>
					<DataSelect dataOption={optionList} defaultOption="6" onChange={this.timeChange}></DataSelect>
				</div>
				<Bar style={{ height: '100%', width: '100%' }} data={this.handleData()} />
			</Card>
		);
	}
}

const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: Function) => {
	return {
		dispatch
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Security);
