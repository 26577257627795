import React, { useEffect, useState } from 'react';
import SOCKET from '../../socket';
import { notification, Button, Modal } from 'antd';
import { Backend as SecurityApi } from '@/api';
import { storage, getSocketConf } from '@/utils';
import ModalSecurityEventDetail from '@/components/ModalSecurityEventDetail';
import { MsgEventTyptEnum } from './interface';
import './index.less';
import { eventTypeList } from '@/pages/security/eventTypes/constant';
import { EventTyptEnum } from '@/pages/security/eventTypes/interface';

interface IMessageRecord {
	content: string;
	messageType: MsgEventTyptEnum;
	customerParams: { processInstanceId: string; id: string; activityId: string };
}

const EVENT_NOTICE_KEY = 'msg_event_notice_key';
const EVENT_NOTICE_TYPE_LIST = [
	MsgEventTyptEnum.HIGH_RISE_LITTERING,
	MsgEventTyptEnum.ELECTRONIC_PATROL,
	MsgEventTyptEnum.VIDEO_PATROL_REPORT,
	MsgEventTyptEnum.DEVICE_STATUS_ALERT,
	MsgEventTyptEnum.DEVICE_ALERT,
	MsgEventTyptEnum.DEVICE_REPAIR
];
const MessageSocket = () => {
	const [eventDetailVisible, setEventDetailVisible] = useState(false);
	const [crtDataItem, setCrtDataItem] = useState<AnyObject>({});
	const [eventTypeItem, setEventTypeItem] = useState<{ name: string; key: EventTyptEnum }>({
		name: '',
		key: EventTyptEnum.HIGH_RISE_LITTERING
	});
	useEffect(() => {
		const appId = storage.getSession('appId');
		if (appId) {
			const host = window.location.host || '192.168.101.66:9001';
			// const host = '192.168.101.66:9001';
			const protocol = window.location.protocol || 'http:';
			new SOCKET({
				url: getSocketConf(host, protocol).url,
				onMessage: onSocketMessage
			}).start();
		}
	}, []);

	const onSocketMessage = (message: string) => {
		const messageObj = JSON.parse(message);
		const data = JSON.parse(messageObj.data);
		const msgRecord = JSON.parse(data.message);
		if (data?.type !== 'NOTICE') {
			openNotification(msgRecord);
		}
	};
	const openNotification = (msgRecord: IMessageRecord) => {
		if (EVENT_NOTICE_TYPE_LIST.includes(msgRecord.messageType)) {
			notification.open({
				message: '',
				key: EVENT_NOTICE_KEY,
				description: (
					<div className="notification-container">
						<span className="notification-title">
							<span className="notification-img"></span>
							<span>消息提示</span>
						</span>
						<span className="notification-content">{msgRecord?.content || ''}</span>
						<Button
							type="primary"
							onClick={() => {
								viewDetail(msgRecord);
								notification.close(EVENT_NOTICE_KEY);
							}}
						>
							查看
						</Button>
					</div>
				),
				className: 'notification-style',
				placement: 'bottomRight',
				duration: 300
			});
		}
	};
	const viewDetail = async (msgRecord: IMessageRecord) => {
		const { customerParams } = msgRecord;
		const loginObj = storage.getSession('login') as { companyId: number };
		const param = {
			pageNum: 1,
			pageSize: 1,
			tenantId: loginObj.companyId,
			separatorValueQueries: [],
			processInstanceId: customerParams.processInstanceId || customerParams.id || customerParams.activityId
		};
		const detailRes: IotResponse = await SecurityApi.getWarningDetail({ body: param });
		if (!detailRes.code) {
			const dataItem = detailRes.data?.content[0];
			const eventType = eventTypeList.filter((item) => item.key === dataItem?.processVariables?.eventType)[0];
			setEventTypeItem(eventType);
			setCrtDataItem(dataItem);
			setEventDetailVisible(true);
		}
	};
	return (
		<Modal
			visible={eventDetailVisible}
			maskClosable={false}
			className="modal-poi-view modal-more-view"
			onCancel={() => setEventDetailVisible(false)}
			destroyOnClose={true}
			footer={null}
			title={eventTypeItem.name}
			width={800}
		>
			<ModalSecurityEventDetail eventType={eventTypeItem} dataSource={crtDataItem} />
		</Modal>
	);
};
export default MessageSocket;
