import * as echarts from 'echarts';

export const getOptionOne = () => {
	const dataText = [
		{
			name: '浊度',
			type: 'line',
			smooth: false,
			data: [60, 50, 0, 40, 0, 0, 0, 0, 60, 0, 80, 0, 100, 0, 0, 0, 40, 0, 0, 0, 0, 70, 0, 0, 100],
			// symbolSize: 4,

			itemStyle: {
				normal: {
					color: 'rgba(0, 255, 0, 1)'
				}
			},
			lineStyle: {
				width: 1
			}
		},
		{
			name: '温度',
			type: 'line',
			smooth: false,
			// symbolSize: 4,
			data: [0, 10, 0, 20, 0, 0, 0, 0, 60, 0, 80, 0, 50, 0, 0, 0, 20, 0, 0, 0, 0, 70, 0, 0, 50],
			itemStyle: {
				normal: {
					color: 'rgba(241, 158, 194, 1)'
				}
			},
			lineStyle: {
				width: 1
			}
		}
	];

	return {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				// 坐标轴指示器，坐标轴触发有效
				type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
			}
		},
		legend: {
			x: 'center',
			bottom: '14%',
			icon: 'roundRect',
			itemWidth: 9,
			itemHeight: 9,
			textStyle: {
				color: '#c1cadf',
				fontSize: 12
			}
		},
		grid: {
			left: '6%',
			right: '7%',
			top: '5%',
			bottom: '20%',
			containLabel: true
		},
		toolbox: {
			show: true,
			orient: 'vertical',
			x: 'right',
			y: 'center'
		},
		xAxis: [
			{
				type: 'category',
				boundaryGap: false,
				data: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 29, 31],
				axisLine: {
					onZero: false,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// minInterval: 4,
				// splitNumber: 4,
				// axisTick: {
				// 	// inside: true,
				// 	show: true,
				// 	length: 4
				// },
				axisLabel: {
					interval: 3,
					color: '#c1cadf',
					fontSize: '12'
				}
			}
		],
		yAxis: [
			{
				type: 'value',
				// min: 0,
				// max: maxNum,
				show: true,
				data: [0, 20, 40, 60, 80],
				axisLine: {
					onZero: false,
					show: true,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// axisTick: {
				// 	inside: true
				// },

				axisLabel: {
					interval: 0,
					color: '#ffffff',
					fontSize: '12'
				},
				splitLine: {
					show: false
				}
			}
		],
		series: dataText
	};
};
export const getOption2 = () => {
	const dataText = [
		{
			name: '用电量',
			type: 'line',
			smooth: false,
			data: [30, 100, 19, 16, 10, 40, 25, 0, 0, 30, 16, 18, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			// symbolSize: 4,

			itemStyle: {
				normal: {
					color: 'rgba(0, 255, 0, 1)'
				}
			},
			lineStyle: {
				width: 1
			}
		}
	];

	return {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				// 坐标轴指示器，坐标轴触发有效
				type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
			}
		},
		legend: {
			x: 'center',
			bottom: '14%',
			icon: 'roundRect',
			itemWidth: 9,
			itemHeight: 9,
			textStyle: {
				color: '#c1cadf',
				fontSize: 12
			}
		},
		grid: {
			left: '6%',
			right: '7%',
			top: '5%',
			bottom: '20%',
			containLabel: true
		},
		toolbox: {
			show: true,
			orient: 'vertical',
			x: 'right',
			y: 'center'
		},
		xAxis: [
			{
				type: 'category',
				boundaryGap: false,
				data: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 29, 31],
				axisLine: {
					onZero: false,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// minInterval: 4,
				// splitNumber: 4,
				// axisTick: {
				// 	// inside: true,
				// 	show: true,
				// 	length: 4
				// },
				axisLabel: {
					interval: 3,
					color: '#c1cadf',
					fontSize: '12'
				}
			}
		],
		yAxis: [
			{
				type: 'value',
				// min: 0,
				// max: maxNum,
				show: true,
				data: [0, 20, 40, 60, 80],
				axisLine: {
					onZero: false,
					show: true,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// axisTick: {
				// 	inside: true
				// },

				axisLabel: {
					interval: 0,
					color: '#ffffff',
					fontSize: '12'
				},
				splitLine: {
					show: false
				}
			}
		],
		series: dataText
	};
};

// bar
export const getOption1 = () => {
	const todayCount: any = [];
	const historyCount: any = [];

	// data?.map((item: any) => {
	// 	todayCount.push(item?.todayCount);
	// 	historyCount.push(item?.historyCount);
	// });
	return {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow'
			}
		},
		legend: {
			show: true,
			x: 'center',
			bottom: 20,
			icon: 'roundRect',
			itemGap: 41,
			itemWidth: 9,
			itemHeight: 9,
			textStyle: {
				color: '#fff',
				fontSize: 14
			}
		},
		grid: {
			left: '3%',
			right: '4%',
			bottom: '16%',
			top: '8%',
			containLabel: true
		},
		xAxis: [
			{
				type: 'category',
				data: ['生活用水', '热水', '二次水', '消防水'],
				show: true,

				axisLabel: {
					show: true,
					color: '#fff'
				},
				axisLine: {
					onZero: false,
					show: true,
					lineStyle: {
						show: true,
						color: 'rgba(0, 160, 229, 1)'
					}
				}
			}
		],
		yAxis: [
			{
				type: 'value',
				show: true,
				data: [0, 2000, 4000, 6000, 8000],
				axisLabel: {
					show: true,
					color: '#fff'
				},
				splitLine: {
					show: true,
					lineStyle: {
						show: true,
						color: 'rgba(0, 160, 229, 0.14)'
					}
				},

				axisLine: {
					onZero: false,
					show: true,
					lineStyle: {
						show: true,
						color: 'rgba(0, 160, 229, 1)'
					}
				}
			}
		],
		color: ['rgba(0, 160, 229, 1)', 'rgba(143, 195, 31, 1)'],
		series: [
			{
				name: '2020',
				type: 'bar',
				barWidth: 12,

				data: [1000, 2000, 1000, 5000]
			},
			{
				name: '2021',
				type: 'bar',
				barWidth: 12,
				data: [1000, 2000, 1000, 5000]
			}
		]
	};
};

export const getOption3 = () => {
	const dataText = [
		{
			name: '当月',
			type: 'line',
			smooth: false,
			data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			// symbolSize: 4,

			itemStyle: {
				normal: {
					color: 'rgba(0, 255, 0, 1)'
				}
			},
			lineStyle: {
				width: 1
			}
		},
		{
			name: '上月',
			type: 'line',
			smooth: false,
			// symbolSize: 4,
			data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			itemStyle: {
				normal: {
					color: 'rgba(241, 158, 194, 1)'
				}
			},
			lineStyle: {
				width: 1
			}
		}
	];

	return {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				// 坐标轴指示器，坐标轴触发有效
				type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
			}
		},
		legend: {
			x: 'center',
			bottom: '14%',
			icon: 'roundRect',
			itemWidth: 9,
			itemHeight: 9,
			textStyle: {
				color: '#c1cadf',
				fontSize: 12
			}
		},
		grid: {
			left: '6%',
			right: '7%',
			top: '5%',
			bottom: '20%',
			containLabel: true
		},
		toolbox: {
			show: true,
			orient: 'vertical',
			x: 'right',
			y: 'center'
		},
		xAxis: [
			{
				type: 'category',
				boundaryGap: false,
				data: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 29, 31],
				axisLine: {
					onZero: false,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// minInterval: 4,
				// splitNumber: 4,
				// axisTick: {
				// 	// inside: true,
				// 	show: true,
				// 	length: 4
				// },
				axisLabel: {
					interval: 3,
					color: '#c1cadf',
					fontSize: '12'
				}
			}
		],
		yAxis: [
			{
				type: 'value',
				// min: 0,
				// max: maxNum,
				show: true,
				data: [0, 20, 40, 60, 80],
				axisLine: {
					onZero: false,
					show: true,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// axisTick: {
				// 	inside: true
				// },

				axisLabel: {
					interval: 0,
					color: '#ffffff',
					fontSize: '12'
				},
				splitLine: {
					show: false
				}
			}
		],
		series: dataText
	};
};

export const getOption4 = () => {
	const dataText = [
		{
			name: '今日',
			type: 'line',
			smooth: false,
			data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			// symbolSize: 4,

			itemStyle: {
				normal: {
					color: 'rgba(0, 255, 0, 1)'
				}
			},
			lineStyle: {
				width: 1
			}
		},
		{
			name: '昨日',
			type: 'line',
			smooth: false,
			// symbolSize: 4,
			data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			itemStyle: {
				normal: {
					color: 'rgba(241, 158, 194, 1)'
				}
			},
			lineStyle: {
				width: 1
			}
		}
	];

	return {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				// 坐标轴指示器，坐标轴触发有效
				type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
			}
		},
		legend: {
			x: 'center',
			bottom: '14%',
			icon: 'roundRect',
			itemWidth: 9,
			itemHeight: 9,
			textStyle: {
				color: '#c1cadf',
				fontSize: 12
			}
		},
		grid: {
			left: '6%',
			right: '7%',
			top: '5%',
			bottom: '20%',
			containLabel: true
		},
		toolbox: {
			show: true,
			orient: 'vertical',
			x: 'right',
			y: 'center'
		},
		xAxis: [
			{
				type: 'category',
				boundaryGap: false,
				data: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 29, 31],
				axisLine: {
					onZero: false,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// minInterval: 4,
				// splitNumber: 4,
				// axisTick: {
				// 	// inside: true,
				// 	show: true,
				// 	length: 4
				// },
				axisLabel: {
					interval: 3,
					color: '#c1cadf',
					fontSize: '12'
				}
			}
		],
		yAxis: [
			{
				type: 'value',
				// min: 0,
				// max: maxNum,
				show: true,
				data: [0, 20, 40, 60, 80],
				axisLine: {
					onZero: false,
					show: true,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// axisTick: {
				// 	inside: true
				// },

				axisLabel: {
					interval: 0,
					color: '#ffffff',
					fontSize: '12'
				},
				splitLine: {
					show: false
				}
			}
		],
		series: dataText
	};
};
