import { IOptionItem } from './interface';

export const highRiseLitteringOptions: IOptionItem[] = [
	{
		label: '设备名称',
		value: 'devName'
	},
	{
		label: '告警时间',
		value: 'reportTime'
	},
	{
		label: '告警位置',
		value: 'devLocation'
	},
	{
		label: '图片信息',
		value: 'faultFile',
		type: 'image'
	},
	{
		label: '视频信息',
		value: 'devId',
		type: 'video'
	}
];

export const electronicPatrolOptions: IOptionItem[] = [
	{
		label: '事件名称',
		value: 'title'
	},
	{
		label: '巡更名称',
		value: 'patrolName'
	},
	{
		label: '点位名称',
		value: 'pointName'
	},
	{
		label: '上报位置',
		value: 'locationDetail'
	},
	{
		label: '上报人',
		value: 'reporterName'
	},
	{
		label: '上报时间',
		value: 'reportTime'
	},
	{
		label: '事件详情',
		value: 'faultDetail'
	},
	{
		label: '图片/视频',
		value: 'faultFile',
		type: 'imageAndvideo'
	}
];

export const videoPatrolReportOptions: IOptionItem[] = [
	{
		label: '事件名称',
		value: 'title'
	},
	{
		label: '设备名称',
		value: 'deviceName'
	},
	{
		label: '巡更名称',
		value: 'videoPatrolEventName'
	},
	{
		label: '上报位置',
		value: 'locationDetail'
	},
	{
		label: '上报人',
		value: 'reporterName'
	},
	{
		label: '上报时间',
		value: 'reportTime'
	},
	{
		label: '事件详情',
		value: 'faultDetail'
	},
	{
		label: '图片信息',
		value: 'faultFile',
		type: 'image'
	},
	{
		label: '视频信息',
		value: 'devId',
		type: 'video'
	}
];

export const deviceStatusAlertOptions: IOptionItem[] = [
	{
		label: '设备类型',
		value: 'devTypeName'
	},
	{
		label: '设备编码',
		value: 'devId'
	},
	{
		label: '设备名称',
		value: 'devName'
	},
	{
		label: '设备IP',
		value: 'ipAddress'
	},
	{
		label: '厂商类型',
		value: 'vendor'
	},
	{
		label: '位置类型',
		value: 'videoType'
	},
	{
		label: '所属区域',
		value: 'areaName'
	},
	{
		label: '所属车场',
		value: 'carParkName'
	},
	{
		label: '所属楼宇',
		value: 'buildName'
	},
	{
		label: '详细地址',
		value: 'devLocation',
		type: 'fullRow'
	},
	{
		label: '预警类型',
		value: 'alarmName'
	},
	{
		label: '预警时间',
		value: 'reportTime'
	}
];

export const deviceRepairOptions: IOptionItem[] = [
	{
		label: '任务类型',
		value: 'taskType'
	},
	{
		label: '任务名称',
		value: 'taskName'
	},
	{
		label: '派工人',
		value: 'assignerName'
	},
	{
		label: '指派时间',
		value: 'designateTime'
	},
	{
		label: '限制时限',
		value: 'timeLimit'
	},
	{
		label: '接收时限',
		value: 'receiveTimeLimit'
	},
	{
		label: '处理时限',
		value: 'dealTimeLimit'
	},
	{
		label: '执行人',
		value: 'dealName'
	},
	{
		label: '配合人',
		value: 'commonDealPeople'
	},
	{
		label: '设备信息',
		value: 'devList',
		type: 'table'
	},
	{
		label: '故障信息',
		value: 'errorInfo',
		type: 'fullRow'
	},
	{
		label: '维修信息',
		value: 'maintenanceInfo',
		type: 'fullRow'
	},
	{
		label: '附件信息',
		value: 'accessory',
		type: 'fileDownLoad'
	}
];

export const defaultOptions: IOptionItem[] = [
	{
		label: '设备名称',
		value: 'deviceName'
	},
	{
		label: '事件时间',
		value: ''
	},
	{
		label: '事件地址',
		value: ''
	},
	{
		label: '事件状态',
		value: 'endTime'
	},
	{
		label: '当前处理人',
		value: ''
	},
	{
		label: '图片',
		value: ''
	}
];
