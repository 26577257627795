// eslint-disable-next-line import/no-anonymous-default-export
export default function (option: { color: any; tooltip: any }, data: { [x: string]: any; tooltip: any }) {
	const { tooltip, ...rest } = data;

	return {
		...option,
		tooltip: {
			...option.tooltip,
			...tooltip
		},
		...rest
	};
}
