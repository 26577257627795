export default [
	{
		title: '运行控制参数',
		info: [
			{
				label: '启动速度',
				value: '0m/s'
			},
			{
				label: '启动速度保持时间',
				value: '0s'
			},
			{
				label: '加速度',
				value: '0m/s'
			},
			{
				label: '拐点加速度',
				value: '1.5s'
			},
			{
				label: '减速度',
				value: '0m/s'
			},
			{
				label: '拐点减速度',
				value: '1.5s'
			},
			{
				label: '特殊减速度',
				value: '0.9m/s'
			},
			{
				label: '停车距离裕量',
				value: '0mm'
			},
			{
				label: '在平层速度',
				value: '0.04m/s'
			},
			{
				label: '检修运行速度',
				value: '0.25mm'
			}
		]
	},
	{
		title: '楼层参数',
		info: [
			{
				label: '平层调整',
				value: '1'
			},
			{
				label: '当前楼层',
				value: '1'
			},
			{
				label: '电梯当前位置高位',
				value: '1'
			},
			{
				label: '电梯当前位置低位',
				value: '1'
			}
		]
	},
	{
		title: '基本参数',
		info: [
			{
				label: '点击查看源网页',
				value: '闭环适量'
			},
			{
				label: '额定速度',
				value: '1m/s'
			},
			{
				label: '命令源选择',
				value: '距离控制'
			},
			{
				label: '额定载重',
				value: '1000kg'
			},
			{
				label: '面板运行速度',
				value: '0.05m/s'
			},
			{
				label: '最大频率',
				value: '50HZ'
			},
			{
				label: '运行速度',
				value: '0.05m/s'
			},
			{
				label: '载波频率',
				value: '6kHZ'
			}
		]
	},
	{
		title: '电机参数',
		info: [
			{
				label: '编码器类型选择',
				value: 'SIN/COS型编码器'
			},
			{
				label: '同步机初始角度',
				value: '0'
			},
			{
				label: '额定功率',
				value: '300KW'
			},
			{
				label: '同步机断电角度',
				value: '0'
			},
			{
				label: '额定电压',
				value: '300W'
			},
			{
				label: '同步机接线方式',
				value: '0'
			},
			{
				label: '额定电流',
				value: '1.5A'
			},
			{
				label: '同步机电流采样延迟',
				value: '0'
			},
			{
				label: '额定频率',
				value: '3Hz'
			},
			{
				label: '编码器教研选择',
				value: '0'
			},
			{
				label: '额定转速',
				value: '25rpm'
			},
			{
				label: '调谐选择',
				value: '无操作'
			}
		]
	}
];
