import { toDecimal2 } from '@/utils';
export const optionDate = (value: number, color: string, num: number) => {
	const option = {
		tooltip: {
			show: true,
			formatter: '已处理 : ' + toDecimal2(value) + '% <br/> 已处理数量：' + num
		},
		series: [
			{
				name: 'Pressure',
				type: 'gauge',
				min: 0,
				max: 100,
				splitNumber: 4,

				startAngle: 220,
				endAngle: -40,
				pointer: {
					width: 2
				},
				progress: {
					show: true,
					width: 3,
					itemStyle: {
						color: color
					}
				},
				axisLine: {
					show: true,
					lineStyle: {
						color: [[1, '#0f5b87']],
						width: 3
					}
				},
				splitLine: {
					show: false
				},
				axisTick: {
					splitNumber: 14,
					length: 4,

					distance: 4,
					lineStyle: {
						color: '#35bfd5'
					}
				},
				axisLabel: {
					distance: 1,
					color: '#42e4fb',
					fontSize: 8
				},
				detail: {
					color: '#02f4f7',
					valueAnimation: true,
					formatter: (value: any) => {
						// console.log(value);
						// return '<span>完成度<span>' + value + '</span></span>';
						return toDecimal2(value) + '%';
						// return value;
					},
					fontWeight: 500,
					fontSize: 12,
					offsetCenter: [0, '25%']
				},
				anchor: {
					show: true,

					showAbove: true,
					size: 4,
					itemStyle: {
						color: '#0c3a4e',
						borderColor: '#04a7d1',
						borderWidth: 0,
						borderType: 'solid'
					}
				},
				itemStyle: {
					color: '#1b74be',
					borderWidth: 0,
					borderCap: 'round',
					borderMiterLimit: 2
				},
				data: [
					{
						value: value
						// name: 'SCORE'
					}
				]
			}
		]
	};
	return option;
};
