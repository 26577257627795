export const getFileType = (fileName: string) => {
	// 后缀获取
	let suffix = '';
	// 获取类型结果
	let result: any = '';
	try {
		const flieArr = fileName.split('.');
		suffix = flieArr[flieArr.length - 1];
	} catch (err) {
		suffix = '';
	}
	suffix = suffix.toLocaleLowerCase();
	// 图片格式
	const imglist = ['png', 'jpg', 'bmp', 'gif', 'unknown'];
	// 进行图片匹配
	result = imglist.find((item) => item === suffix);
	if (result) {
		return 'image';
	}
	// 匹配 视频
	const videolist = ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'];
	result = videolist.find((item) => item === suffix);
	if (result) {
		return 'video';
	}
	// 其他 文件类型
	return 'other';
};
