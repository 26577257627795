import { toDecimal2 } from '@/utils';

export const optionDate = (value: number) => {
	const option = {
		title: {
			text: ''
		},

		legend: {
			data: ['空调', '其他', '动力', '照明']
		},
		radar: {
			shape: 'circle',
			radius: '70%',
			axisLabel: { show: false, color: '#fff', hideOverlap: 'true' },
			splitArea: {
				areaStyle: {
					opacity: 0
				}
			},
			splitLine: {
				lineStyle: { color: ['#00B7EE'] }
			},
			indicator: [
				{ name: '照明', max: 6500, color: '#fff' },
				{ name: '其他', max: 16000, color: '#fff' },
				{ name: '动力', max: 38000, color: '#fff' },
				{ name: '空调', max: 52000, color: '#fff' }
			]
		},
		series: [
			{
				name: '',
				type: 'radar',
				data: [
					{
						value: [4200, 3000, 20000, 35000, 50000, 18000],
						name: ''
					}
				],
				itemStyle: {
					color: '#00FF00'
				},
				lineStyle: {
					color: '#00FF00',
					width: 1
				},
				areaStyle: {
					color: '#00FF00',
					opacity: 0.2
				}
			}
		]
	};
	return option;
};
