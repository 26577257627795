import React, { Component } from 'react';
import { Props, State } from './AppTypes';
import * as redux from 'react-redux';
import { storage } from '@/utils';
import './App.less';
import { renderRoutes } from 'react-router-config';
import { Route } from '@/router';
import AreaSelect from '@/components/AreaSelect';
import AppHeader from '@/components/AppHeader';
import GDMap from '@/components/GDMap';
import MessageSocket from './template/MessageSocket';
import ThingJsMap from './components/ThingJsMap';
const { connect } = redux;
class App extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			crtRoutes: [],
			mapType: ''
		};
	}

	componentDidMount() {
		this.setState({ mapType: storage.getSession('thingJsParams')?.length ? 'ThingJsMap' : 'GDMap' });
		this.isLogin();
		this.initRoute();
	}
	isLogin = () => {
		const { history, dispatch } = this.props;
		if (!storage.getSession('appId')) {
			history.replace('/login');
			return;
		}
		if (this.props.location.pathname === '/index') {
			dispatch({
				type: 'CURRENTROUTEPATH',
				data: '/overview'
			});
			storage.setSession('CURRENTROUTEPATH', '/overview');
			history.replace('/overview');
		}
	};
	initRoute = () => {
		const pageIds = storage.getSession('pageIds') || [];
		const crtRoutes = this.props.route.routes.filter((routeItem: Route) => {
			return pageIds.includes(routeItem.path.slice(1));
		});
		this.setState({ crtRoutes });
	};
	render() {
		const loginRes = storage.getSession('login');
		const roles = typeof loginRes === 'object' && loginRes.roles;
		const pageIds = storage.getSession('pageIds') || [];
		const isAdmin = typeof roles === 'object' ? roles.find((item: any) => item.role === 'CompanyAdmin') : {};
		let pathname = this.props.location.pathname;
		if (pageIds.length > 0) {
			pathname = pathname.replace(/(\/)/g, '_').replace('_', '');
			// 如果地址栏强制跳转根路径 且用户已登录 则跳转首页
			if (pathname === '' && typeof loginRes === 'object' && pageIds.includes('overview')) {
				this.props.history.push('/overview');
			}
		}
		const { crtRoutes, mapType } = this.state;

		return (
			<div className="app-page">
				<div className="app-header">
					<AppHeader {...this.props} crtRoutes={isAdmin ? this.props.route.routes : crtRoutes} />
				</div>
				{mapType === 'ThingJsMap' ? <ThingJsMap /> : null}
				<div
					style={
						mapType === 'GDMap'
							? { visibility: 'visible', width: '100%', height: '100%', position: 'absolute' }
							: { visibility: 'hidden', width: '0', height: '0' }
					}
				>
					<GDMap />
				</div>
				<div className="route-container">
					{!isAdmin ? renderRoutes(crtRoutes) : renderRoutes(this.props.route.routes)}
				</div>
				<div className="app-area-select">
					<i className="iconfont icon-dingwei" />
					<div className="area-select-view">{this.props.mapFinish ? <AreaSelect /> : null}</div>
				</div>
				<MessageSocket />
			</div>
		);
	}
}
const mapStateToProps = (store: {}) => store;
const mapDispatchToProps = (dispatch: void) => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(App);
