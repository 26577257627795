import React, { Component } from 'react';

import * as redux from 'react-redux';
import { Props, State } from './interface';
import classes from './index.module.css';
import '@/static/css/base.css';
import Card from '@/components/Card';
import Bar from '@/components/Charts/Bar/index';
import { getOption } from './lineOption';

const { box, left, right, progressThree, mediaBox } = classes;
const { connect } = redux;

class Monitoring extends Component<Props, State> {
	videoBody: any;
	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		return null;
	}

	constructor(props: Props) {
		super(props);
		this.state = {};
	}

	render() {
		const { data } = this.props;
		return (
			<div className={mediaBox}>
				<Card titleName="停车时长统计">
					<Bar style={lineStyle} data={getOption(data)} />
				</Card>
			</div>
		);
	}
}

const lineStyle: any = {
	width: '90%',
	height: '25vh',
	position: 'absolute',
	top: '-20rem'
};

const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: Function) => {
	return {
		dispatch
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Monitoring);
