import { storage } from '@/utils';
import React from 'react';
import './index.less';

function ThingJsMap() {
	const thingJsParams = storage.getSession('thingJsParams');
	return (
		<div className="map-container">
			<div className="map-container-inner">
				<iframe
					allowFullScreen
					id="thingJsIframe"
					src={thingJsParams[0]?.param?.url}
					title="ThingJsTitle"
					width="100%"
					height="100%"
				></iframe>
			</div>
		</div>
	);
}

export default ThingJsMap;
