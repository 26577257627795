import { toDecimal2 } from '@/utils';

export const optionDate = (value: number) => {
	const option = {
		tooltip: {
			show: true,
			formatter: '{b} : ' + toDecimal2(value) + '%'
		},
		series: [
			{
				name: 'Pressure',
				type: 'gauge',
				min: 0,
				max: 100,
				splitNumber: 4,

				startAngle: 220,
				endAngle: -40,
				title: {
					color: '#fff',
					offsetCenter: [0, '40%'],
					fontSize: window.innerWidth * 0.007
				},
				progress: {
					show: true,
					itemStyle: {
						color: '#F19EC2'
					}
				},
				axisLine: {
					show: true,
					lineStyle: {
						color: [[1, '#0f5b87']],
						width: 10
					}
				},
				splitLine: {
					show: false
				},
				axisTick: {
					splitNumber: 14,
					length: 6,

					distance: 4,
					lineStyle: {
						color: '#35bfd5'
					}
				},
				axisLabel: {
					distance: 4,
					color: '#42e4fb'
				},
				detail: {
					color: '#02f4f7',
					valueAnimation: true,
					formatter: (value: any) => {
						return toDecimal2(value) + '%';
					},
					fontWeight: 500,
					fontSize: 22,
					offsetCenter: [0, '75%']
				},
				anchor: {
					show: true,
					showAbove: true,
					size: 10,
					itemStyle: {
						borderColor: '#02C6F7',
						borderWidth: 4,
						borderType: 'solid'
					}
				},
				pointer: {
					length: 45,
					width: 4
				},
				itemStyle: {
					color: {
						type: 'linear',
						x: 0,
						y: 0,
						x2: 0,
						y2: 1,
						colorStops: [
							{
								offset: 0,
								color: '#FFFFFF' // 0% 处的颜色
							},
							{
								offset: 1,
								color: '#E6BD4A' // 100% 处的颜色
							}
						]
					}
				},
				data: [
					{
						value: value,
						name: '在线率'
					}
				]
			}
		]
	};
	return option;
};
