import * as echarts from 'echarts';

export const getOption = (data: any[]) => {
	const todayCount: any = [];
	const historyCount: any = [];

	data?.map((item: any) => {
		todayCount.push(item?.todayCount);
		historyCount.push(item?.historyCount);
	});
	return {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow'
			}
		},
		legend: {
			show: true,
			x: 'center',
			bottom: 5,
			// icon: 'roundRect',
			itemGap: 41,
			itemWidth: 9,
			itemHeight: 9,
			textStyle: {
				color: '#fff',
				fontSize: 14
			}
		},
		grid: {
			top: '13%',
			left: '3%',
			right: '4%',
			bottom: '13%',
			containLabel: true
		},
		xAxis: [
			{
				type: 'category',
				data: ['<2小时', '2-4小时', '4-8小时', '8-12小时', '>12小时'],
				show: true,

				axisLabel: {
					show: true,
					color: '#fff'
				},
				axisLine: {
					onZero: false,
					show: true,
					lineStyle: {
						show: true,
						color: 'rgba(0, 160, 229, 1)'
					}
				}
			}
		],
		yAxis: [
			{
				type: 'value',
				show: true,

				axisLabel: {
					show: true,
					color: '#fff'
				},
				splitLine: {
					show: true,
					lineStyle: {
						show: true,
						color: 'rgba(0, 160, 229, 0.14)'
					}
				},

				axisLine: {
					onZero: false,
					show: true,
					lineStyle: {
						show: true,
						color: 'rgba(0, 160, 229, 1)'
					}
				}
			}
		],
		color: ['rgba(0, 160, 233, 1)', 'rgba(143, 195, 31, 1)'],
		series: [
			{
				name: '历史均值',
				type: 'bar',
				barWidth: 11,

				data: historyCount
			},
			{
				name: '今日车辆',
				type: 'bar',
				barWidth: 11,
				data: todayCount
			}
		]
	};
};
