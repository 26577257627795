import { toDecimal2 } from '@/utils';

export const optionDate = (value: number) => {
	const option = {
		tooltip: {
			show: true,
			formatter: '{b} : ' + toDecimal2(value) + '%'
		},
		series: [
			{
				name: 'Pressure',
				type: 'gauge',
				min: 0,
				max: 100,
				splitNumber: 4,
				startAngle: 220,
				endAngle: -40,
				title: {
					color: '#fff',
					offsetCenter: [0, '40%'],
					fontSize: window.innerWidth * 0.007
				},
				progress: {
					show: true,
					itemStyle: {
						color: '#f9c626'
					}
				},
				axisLine: {
					show: true,
					lineStyle: {
						color: [[1, '#0f5b87']],
						width: 10
					}
				},
				splitLine: {
					show: false
				},
				axisTick: {
					splitNumber: 14,
					length: 6,
					distance: 4,
					lineStyle: {
						color: '#35bfd5'
					}
				},
				axisLabel: {
					distance: 4,
					color: '#42e4fb'
				},
				detail: {
					color: '#02f4f7',
					valueAnimation: true,
					formatter: (value: any) => {
						return toDecimal2(value) + '%';
					},
					fontWeight: 500,
					fontSize: 22,
					offsetCenter: [0, '75%']
				},
				anchor: {
					show: true,
					showAbove: true,
					size: 10,
					itemStyle: {
						color: '#0c3a4e',
						borderColor: '#04a7d1',
						borderWidth: 4,
						borderType: 'solid'
					}
				},
				itemStyle: {
					color: '#e8d18d'
				},
				data: [
					{
						value: value,
						name: '完成率'
					}
				]
			}
		]
	};
	return option;
};
