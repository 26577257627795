import React, { Component } from 'react';

import * as redux from 'react-redux';
import { Props, State } from './interface';
import classes from './index.module.css';
import Card from '@/components/Card';
import Line from '@/components/Charts/Line/index';
import { getOptionOne } from './LineOption';
import DataSelect from '@/components/DataSelect/index';

const { tab, active } = classes;
const { connect } = redux;

class AccessInfo extends Component<Props, State> {
	componentDidMount(): void {}

	timeChange = (startDate: any, endDate: any, day: string) => {
		this.props.onChange && this.props.onChange(startDate, endDate, day);
	};

	render() {
		const { dataList } = this.props;
		return (
			<Card titleName="访客信息" style={cardStyle}>
				<div style={selectStyle}>
					<DataSelect dataOption={optionList} defaultOption="" onChange={this.timeChange}></DataSelect>
				</div>
				<Line style={lineStyle} data={getOptionOne(dataList)} />
			</Card>
		);
	}
}

const optionList = [
	{
		label: '全部',
		value: ''
	},
	{
		label: '近七日',
		value: '7'
	},
	{
		label: '本月',
		value: '0'
	},
	{
		label: '近三月',
		value: '3'
	}
];

const lineStyle: any = {
	height: '100%',
	width: '100%'
};

const cardStyle: any = {
	height: '100%',
	width: '100%'
};
const selectStyle: any = {
	position: 'absolute',
	top: '-26rem',
	right: '1rem'
};

const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: Function) => {
	return {
		dispatch
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(AccessInfo);
