import React, { useRef, Fragment, useState, useImperativeHandle, forwardRef } from 'react';
import classNames from './index.module.css';
import SecurityOverview from './securityOverview';
import RightLogin from './rightLogin';
import RightOut from './rightOut';
import VehicleLive from '@/pages/overview/vehicleLive';

interface Props {
	pageInfo?: any;
}

const RightOne = (props: Props, ref: any) => {
	const rightFireRef = useRef<any>(null);
	const rightTrendRef = useRef<any>(null);
	const [active, setActive] = useState<number>(0);
	const { pageInfo } = props;
	useImperativeHandle(ref, () => {
		return {
			initRightThree
		};
	});
	const initRightThree = () => {
		rightFireRef?.current?.getRightFireData();
		rightTrendRef?.current?.getLineData();
	};
	const handleToggle = (curIndex: number) => {
		setActive(curIndex);
	};
	const visiterList = [
		{ active: 0, text: '访客登记' },
		{ active: 1, text: '访客出入' }
	];
	return (
		<Fragment>
			<div className={classNames.pageContent}>
				<div style={{ height: '30%', position: 'relative' }}>
					<div className={classNames.visiterToggle}>
						{visiterList.map((item: any, index: number) => {
							return (
								<div
									className={active === item.active ? classNames.left : ''}
									key={item.active}
									onClick={() => {
										handleToggle(item.active);
									}}
								>
									{item.text}
								</div>
							);
						})}
					</div>
					{active === 0 ? <RightLogin ref={rightTrendRef} /> : <RightOut ref={rightTrendRef} />}
				</div>
				<div style={{ height: '30%', marginTop: '20rem' }}>
					<SecurityOverview pageInfo={pageInfo} />
				</div>
				<div style={{ height: '33%', marginTop: '20rem' }}>
					<VehicleLive data={pageInfo?.IN_OUT_RECORD?.toadyCarRecordVOList || []} />
				</div>
			</div>
		</Fragment>
	);
};

export default forwardRef(RightOne);
