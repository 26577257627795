enum AccessTypeEnum {
	ENTRANCE = 'in',
	GO_OUT = 'out'
}
export const generatorLine = (data?: any, monthList?: any): AnyObject => {
	const XList = monthList || [];
	let seriseInData: any = [],
		seriseOutData: any = [];
	if (Array.from(data) && data.length) {
		data?.map((item: any, index: number) => {
			if (XList.length && item.value && !XList.includes(item.value)) {
				if (timeCompare(item.value, XList[0])) {
					XList.push(item.value);
					XList.splice(0, 1);
				} else {
					XList.unshift(item.value);
				}
			}
		});
		data.sort((num1: any, num2: any) => {
			return timeCompare(num2.value, num1.value);
		});
	}

	const setSeriesData = (
		xdata: string[],
		ydata: { enterOrExit: AccessTypeEnum; value: string; key: string }[],
		type: AccessTypeEnum
	) => {
		return xdata.map((item: string) => ({
			name: item,
			value:
				ydata.find(
					(d: { enterOrExit: AccessTypeEnum; value: string; key: string }) =>
						d.enterOrExit === type && d.value === item
				)?.key || 0
		}));
	};
	seriseInData = setSeriesData(XList, data, AccessTypeEnum.ENTRANCE);
	seriseOutData = setSeriesData(XList, data, AccessTypeEnum.GO_OUT);
	const dataText = [
		{
			name: '进场',
			type: 'line',
			smooth: true,
			showSymbol: monthList.length === 1,
			symbolSize: 4,
			data: seriseInData,
			itemStyle: {
				normal: {
					color: '#03E603'
				}
			},
			lineStyle: {
				width: 1
			},
			areaStyle: {
				normal: {
					color: {
						type: 'linear',
						x: 0,
						y: 0,
						x2: 0,
						y2: 1,
						colorStops: [
							{
								offset: 0,
								color: 'rgba(3, 230, 3, 0.3)' // 0% 处的颜色
							},
							{
								offset: 1,
								color: 'rgba(3, 230, 3, 0)' // 100% 处的颜色
							}
						],
						global: false // 缺省为 false
					},
					shadowColor: 'rgba(3, 230, 3, 0.9)',
					shadowBlur: 20
				}
			}
		},
		{
			name: '出场',
			type: 'line',
			smooth: true,
			showSymbol: monthList.length === 1,
			symbolSize: 4,
			data: seriseOutData,
			itemStyle: {
				normal: {
					color: '#EC6941'
				}
			},
			lineStyle: {
				width: 1
			},
			areaStyle: {
				normal: {
					color: {
						type: 'linear',
						x: 0,
						y: 0,
						x2: 0,
						y2: 1,
						colorStops: [
							{
								offset: 0,
								color: 'rgba(236, 105, 65, 0.3)' // 0% 处的颜色
							},
							{
								offset: 1,
								color: 'rgba(236, 105, 65, 0)' // 100% 处的颜色
							}
						],
						global: false // 缺省为 false
					},
					shadowColor: 'rgba(236, 105, 65, 0.9)',
					shadowBlur: 20
				}
			}
		}
	];
	return {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				// 坐标轴指示器，坐标轴触发有效
				type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
			}
		},
		legend: {
			x: 'center',
			bottom: '5%',
			textStyle: {
				color: '#c1cadf',
				fontSize: 12
			}
		},
		grid: {
			left: '5',
			right: '25',
			top: '10%',
			bottom: '16%',
			containLabel: true
		},
		toolbox: {
			show: true,
			orient: 'vertical',
			x: 'right',
			y: 'center'
		},
		dataZoom: [
			{
				type: 'inside',
				start: 0,
				end: monthList.length > 6 ? 35 : 100
			}
		],
		xAxis: [
			{
				type: 'category',
				boundaryGap: true,
				data: XList,
				axisTick: {
					inside: true
				},
				axisLabel: {
					color: '#ffffff',
					fontSize: '12'
				}
			}
		],
		yAxis: [
			{
				type: 'value',
				show: true,
				min: 0,
				max: function (value: { max: number }) {
					return value.max < 120 ? 120 : value.max;
				},
				axisLine: {
					show: true,
					onZero: false,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				axisTick: {
					inside: true
				},
				axisLabel: {
					interval: 0,
					color: '#ffffff',
					fontSize: '12'
				},
				splitLine: {
					show: false
				}
			}
		],
		series: dataText
	};
};

export const timeCompare = (time1: string, time2: string) => {
	const time1List = time1.split('.');
	const time2List = time2.split('.');
	if (time1List[0] > time2List[0]) {
		return true;
	} else if (time1List[0] === time2List[0]) {
		if (time1List[1] >= time2List[1]) {
			return true;
		}
	}
	return false;
};
