import React, { Component } from 'react';

import * as redux from 'react-redux';
import * as echarts from 'echarts';
import { Props, State } from './interface';
import classes from './index.module.css';
import Card from '@/components/Card';
import Line from '@/components/Charts/Line/index';
import DataSelect from '@/components/DataSelect/index';
import { getOption } from './lineOption';
import { getdataList } from '@/utils';
import { optionList } from './selectOption';

const { connect } = redux;

class Security extends Component<Props, State> {
	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		return null;
	}

	constructor(props: Props) {
		super(props);
		this.state = {};
	}

	componentDidMount(): void {}

	timeChange = (startDate: string, endDate: string) => {
		this.props.onChange && this.props.onChange(startDate, endDate);
	};
	handleData = () => {
		const { data, time } = this.props;
		const start = time.split('|')[0];
		const end = time.split('|')[1];
		const data1: number[] = [];
		const data2: number[] = [];

		data?.finished?.map((item, index) => {
			data1.push(item.value);
		});
		data?.all?.map((item, index) => {
			data2.push(item.value);
		});
		return getOption(data, getdataList(start, end));
	};
	render() {
		return (
			<Card titleName="设备预警趋势" style={cardStyle}>
				<div
					style={{
						position: 'absolute',
						top: '-26rem',
						right: '1rem'
					}}
				>
					<DataSelect dataOption={optionList} defaultOption="6" onChange={this.timeChange}></DataSelect>
				</div>
				<Line style={{ height: '100%', width: '100%' }} data={this.handleData()} />
			</Card>
		);
	}
}

const cardStyle: any = {
	width: '100%',
	height: '100%'
};

const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: Function) => {
	return {
		dispatch
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Security);
