import React, { Component } from 'react';
import { Progress } from 'antd';
import * as redux from 'react-redux';
import { Props, State } from './interface';
import classes from './index.module.css';
import Card from '@/components/Card';
import DataSelect from '@/components/DataSelect/index';
import { toDecimal2 } from '@/utils';

const { box, left, right } = classes;
const { connect } = redux;

const stateData = {
	finished: 0,
	unfinished: 0,
	total: 0
};
const optionList = [
	{
		label: '全部',
		value: ''
	},
	{
		label: '本月',
		value: '0'
	},
	{
		label: '近三月',
		value: '3'
	},
	{
		label: '近半年',
		value: '6'
	},
	{
		label: '近一年',
		value: '12'
	}
];
class Statistics extends Component<Props, State> {
	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		const { data } = nextProps;
		const obj: {
			finished: number;
			unfinished: number;
			total: number;
		} = { finished: 0, unfinished: 0, total: 0 };
		if (data?.length > 1) {
			if (data[0].name === 'finished') {
				obj.finished = data[0]?.value;
				obj.unfinished = data[1]?.value;
			} else {
				obj.finished = data[1]?.value;
				obj.unfinished = data[0]?.value;
			}
		}

		obj.total = obj.unfinished + obj.finished;
		return {
			data: obj
		};
	}

	constructor(props: Props) {
		super(props);
		this.state = {
			data: stateData
		};
	}

	componentDidMount(): void {}
	timeChange = (startDate: any, endDate: any) => {
		this.props.onChange && this.props.onChange(startDate, endDate);
	};
	render() {
		const {
			data: { finished, unfinished, total }
		} = this.state;
		const { DAILY_WORK, EMERGENCY_WORK, PLAN_WORK } = this.props.statisticsNumber;
		return (
			<Card titleName="保养维修统计" style={cardStyle}>
				<div style={selectDivStyle}>
					<DataSelect dataOption={optionList} defaultOption="" onChange={this.timeChange}></DataSelect>
				</div>
				<div className={box}>
					<div className={classes.infoBox}>
						<div className={`${classes.circle} ${classes.circle1}`}>
							<i>{PLAN_WORK}</i>
							<i>保养工单</i>
						</div>
						<div className={`${classes.circle} ${classes.circle2}`}>
							<i>{DAILY_WORK}</i>
							<i>日常工单</i>
						</div>
						<div className={`${classes.circle} ${classes.circle3}`}>
							<i>{EMERGENCY_WORK}</i>
							<i>紧急工单</i>
						</div>
					</div>
					<div className={classes.right}>
						<div>
							<div>
								<span>
									已完成 <span>{(toDecimal2((finished / total) * 100) || '0.00') + '%'}</span>
								</span>

								<span>{finished}</span>
							</div>
							<Progress
								className="progress-two"
								type="line"
								percent={Number(toDecimal2((finished / total) * 100))}
								showInfo={false}
								strokeColor="rgba(253, 164, 0, 1)"
								strokeWidth={4}
								trailColor="rgba(4, 98, 138, 0.9)"
							/>
						</div>
						<div>
							<div>
								<span>
									处理中
									<span>
										<span>
											{unfinished !== 0
												? toDecimal2(100 - toDecimal2((finished / total) * 100) || '0.00') + '%'
												: '0.00%'}
										</span>
									</span>
								</span>

								<span>{unfinished}</span>
							</div>
							<Progress
								className="progress-one"
								type="line"
								percent={Number(toDecimal2(unfinished / total) * 100)}
								showInfo={false}
								strokeColor="rgba(90, 208, 73, 1"
								strokeWidth={4}
								trailColor="rgba(4, 98, 138, 0.9)"
							/>
						</div>
					</div>
				</div>
			</Card>
		);
	}
}

const cardStyle: any = {
	width: '100%',
	height: '100%'
};
const selectDivStyle: any = {
	position: 'absolute',
	top: '-26rem',
	right: '1rem'
};
const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: Function) => {
	return {
		dispatch
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
