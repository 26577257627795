import React, { useRef, Fragment, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import classNames from './index.module.css';
import Card from '@/components/Card';
import ParkDevice from './parkDevice';
import SpaceDesc from './spaceDesc';
import AreaInfo from './areaInfo';

interface Props {
	pageInfo?: any;
}
const LeftPage = (props: any, ref: any) => {
	const [deviceCheckInfo, setDeviceCheckInfo] = useState<any>([]);
	const parkDeviceRef = useRef<any>(null);
	const { pageInfo } = props;

	useImperativeHandle(ref, () => {
		return {
			initThree
		};
	});

	const initThree = () => {
		parkDeviceRef?.current?.getParkDevice();
	};
	return (
		<div className={classNames.pageContent}>
			<div style={{ height: '32%' }}>
				<SpaceDesc pageInfo={pageInfo}></SpaceDesc>
			</div>
			<div style={{ height: '30%', marginTop: '20rem' }}>
				<AreaInfo pageInfo={pageInfo}></AreaInfo>
			</div>
			<div style={{ height: '31%', marginTop: '20rem' }}>
				<ParkDevice ref={parkDeviceRef}></ParkDevice>
			</div>
		</div>
	);
};

export default forwardRef(LeftPage);
