export const geneFireFightingLine = (data?: any, monthList?: any): AnyObject => {
	const XList = monthList || [];
	const seriseData: any = [];
	if (Array.from(data) && data.length) {
		data?.map((item: any, index: number) => {
			if (XList.length && item.value && !XList.includes(item.value)) {
				if (timeCompare(item.value, XList[0])) {
					XList.push(item.value);
					XList.splice(0, 1);
				} else {
					XList.unshift(item.value);
				}
			}
		});
		data.sort((num1: any, num2: any) => {
			return timeCompare(num2.value, num1.value);
		});
	}
	XList.map((item: any, index: number) => {
		if (Array.from(data) && data.length) {
			const curHas: any = data.find((curItem: any) => {
				return curItem.value === item;
			});

			if (curHas) {
				seriseData.push({
					name: item,
					value: curHas.key
				});
			} else {
				seriseData.push({
					name: item,
					value: 0
				});
			}
		} else {
			seriseData.push({
				name: item,
				value: 0
			});
		}
	});

	const dataText = [
		{
			name: '访客趋势',
			type: 'bar',
			smooth: true,
			showSymbol: monthList.length === 1,
			symbolSize: 4,
			data: seriseData,
			itemStyle: {
				normal: {
					color: 'rgba(0, 160, 233, 1)'
				}
			},
			barWidth: '15%',
			lineStyle: {
				width: 1
			}
		}
	];

	return {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				// 坐标轴指示器，坐标轴触发有效
				type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
			}
		},
		legend: {
			x: 'center',
			bottom: '0',
			show: false,
			textStyle: {
				color: '#c1cadf',
				fontSize: 12
			}
		},
		grid: {
			left: '5',
			right: '25',
			top: '10%',
			bottom: '3%',
			containLabel: true
		},
		toolbox: {
			show: true,
			orient: 'vertical',
			x: 'right',
			y: 'center'
		},
		dataZoom: [
			{
				type: 'inside',
				start: 0,
				end: monthList.length > 6 ? 35 : 100
			}
		],
		xAxis: [
			{
				type: 'category',
				boundaryGap: true,
				data: XList,
				// axisLine: {
				// 	onZero: false,
				// 	lineStyle: {
				// 		color: '#00A0E5'
				// 	}
				// },
				axisTick: {
					inside: true
				},
				axisLabel: {
					//坐标轴刻度标签的相关设置。
					// formatter: (params: any, index: number) => {
					// 	if (XList.length < 7) {
					// 		return params;
					// 	}
					// 	let newParamsName = ''; // 最终拼接成的字符串
					// 	const paramsNameNumber = params.length; // 实际标签的个数
					// 	const provideNumber = 2; // 每行能显示的字的个数
					// 	const rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
					// 	/**
					// 	 * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
					// 	 */
					// 	// 条件等同于rowNumber>1
					// 	if (paramsNameNumber > provideNumber) {
					// 		/** 循环每一行,p表示行 */
					// 		for (let p = 0; p < rowNumber; p++) {
					// 			let tempStr = ''; // 表示每一次截取的字符串
					// 			const start = p * provideNumber; // 开始截取的位置
					// 			const end = start + provideNumber; // 结束截取的位置
					// 			// 此处特殊处理最后一行的索引值
					// 			if (p === rowNumber - 1) {
					// 				// 最后一次不换行
					// 				tempStr = params.substring(start, paramsNameNumber);
					// 			} else {
					// 				// 每一次拼接字符串并换行
					// 				tempStr = params.substring(start, end) + '\n';
					// 			}
					// 			newParamsName += tempStr; // 最终拼成的字符串
					// 		}
					// 	} else {
					// 		// 将旧标签的值赋给新标签
					// 		newParamsName = params;
					// 	}

					// 	//将最终的字符串返回
					// 	return newParamsName;
					// },
					color: '#ffffff',
					fontSize: '12'
				}
			}
		],
		yAxis: [
			{
				type: 'value',
				show: true,
				min: 0,
				max: function (value: { max: number }) {
					return value.max < 120 ? 120 : value.max;
				},
				axisLine: {
					show: true,
					onZero: false,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				axisTick: {
					inside: true
				},
				axisLabel: {
					interval: 0,
					color: '#ffffff',
					fontSize: '12'
				},
				splitLine: {
					show: false
				}
			}
		],
		series: dataText
	};
};

export const timeCompare = (time1: string, time2: string) => {
	const time1List = time1.split('.');
	const time2List = time2.split('.');
	if (time1List[0] > time2List[0]) {
		return true;
	} else if (time1List[0] === time2List[0]) {
		if (time1List[1] >= time2List[1]) {
			return true;
		}
	}
	return false;
};
