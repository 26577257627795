import React, { Component, useState, useEffect } from 'react';
import Bar from '@/components/Charts/Bar/index';
import Line from '@/components/Charts/Bar/index';
import { getOptionOne, getOption1, getOption2, getOption3, getOption4 } from './lineOption';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import './index.less';
interface Props {
	type: 'list' | 'map' | 'loop';
}

const StatisticalTrends = (props: Props) => {
	if (props.type === 'map') {
		return (
			<div className="statistical_trends">
				<div style={{ width: '399rem', height: '265rem', border: '1px solid #00A0E5' }}>
					<div className="statistical_trends_title">园区月度供水温度、浊度平均值统计</div>
					<div className="statistical_trends_tips">
						<span>单位：摄氏度℃</span>
						<span>单位：NTU</span>
					</div>
					<Line data={getOptionOne()} style={{ height: '210rem' }} />
				</div>
				<div style={{ width: '399rem', height: '286rem', border: '1px solid #00A0E5', marginTop: '16rem' }}>
					<div className="statistical_trends_title">
						园区各水系统使用量趋势对比 <span>单位：吨</span>
					</div>
					<Bar data={getOption1()} style={{ height: '250rem' }} />
				</div>
				<div style={{ width: '399rem', height: '265rem', border: '1px solid #00A0E5', marginTop: '16rem' }}>
					<div className="statistical_trends_title">
						园区给排水系统用电趋势图<span>单位：kw·h</span>
					</div>
					<Line data={getOption2()} style={{ height: '240rem' }} />
				</div>
			</div>
		);
	} else {
		return (
			<div className="statistical_trends">
				<div style={{ width: '399rem', height: '110rem', border: '1px solid #00A0E5', padding: '19rem 25rem' }}>
					<p>
						<span>今日</span>:<span>1482.0 kw/h</span>
					</p>
					<p>
						<span>昨日</span>:<span>1954.0 kw/h</span>
					</p>
					<p>
						<span>同比</span>:<span style={{ color: 'rgba(0, 255, 0, 1)' }}>-24.2 </span>{' '}
						<ArrowDownOutlined style={{ color: 'rgba(0, 255, 0, 1)' }} />
					</p>
				</div>

				<div
					style={{
						width: '399rem',
						height: '110rem',
						border: '1px solid #00A0E5',
						padding: '19rem 25rem',
						marginTop: '20rem'
					}}
				>
					<p>
						<span>当月</span>:<span>1482.0 kw/h</span>
					</p>
					<p>
						<span>上月</span>:<span>1954.0 kw/h</span>
					</p>
					<p>
						<span>同比</span>:<span style={{ color: 'rgba(230, 0, 18, 1)' }}>24.2 </span>{' '}
						<ArrowUpOutlined style={{ color: 'rgba(230, 0, 18, 1)' }} />
					</p>
				</div>
				<div
					style={{
						width: '399rem',
						height: '110rem',
						border: '1px solid #00A0E5',
						padding: '19rem 25rem',
						marginTop: '20rem'
					}}
				>
					<p>
						<span>今日</span>:<span>1482.0 kw/h</span>
					</p>
					<p>
						<span>昨日</span>:<span>1954.0 kw/h</span>
					</p>
					<p>
						<span>同比</span>:<span style={{ color: 'rgba(230, 0, 18, 1)' }}>24.2 </span>{' '}
						<ArrowUpOutlined style={{ color: 'rgba(230, 0, 18, 1)' }} />
					</p>
				</div>

				<div style={{ width: '399rem', height: '238rem', border: '1px solid #00A0E5', marginTop: '16rem' }}>
					<div className="statistical_trends_title">
						当月逐日趋势<span style={{ right: 10 }}></span>
					</div>
					<div className="statistical_trends_tips">
						<span>单位：kw·h</span>
					</div>
					<Line data={getOption3()} style={{ height: '190rem' }} />
				</div>
				<div style={{ width: '399rem', height: '238rem', border: '1px solid #00A0E5', marginTop: '16rem' }}>
					<div className="statistical_trends_title">当日逐日趋势</div>
					<div className="statistical_trends_tips">
						<span>单位：kw·h</span>
					</div>
					<Line data={getOption4()} style={{ height: '190rem' }} />
				</div>
			</div>
		);
	}
};

export default StatisticalTrends;
