import { EventTyptEnum, TaskTypeEnum, TimeLimitEnum } from './interface';

export const eventTypeList = [
	{
		name: '高空抛物告警',
		key: EventTyptEnum.HIGH_RISE_LITTERING
	},
	{
		name: '电子巡更事件',
		key: EventTyptEnum.ELECTRONIC_PATROL
	},
	{
		name: '视频巡更事件',
		key: EventTyptEnum.VIDEO_PATROL_REPORT
	},
	{
		name: '设备状态预警',
		key: EventTyptEnum.DEVICE_STATUS_ALERT
	},
	{
		name: '设备预警',
		key: EventTyptEnum.DEVICE_ALERT
	},
	{
		name: '设备维修',
		key: EventTyptEnum.DEVICE_REPAIR
	}
];

export const locationMap: any = {
	PUBLIC: '公共区域',
	CARPARK: '车场区域',
	BUILD: '楼宇区域'
};

export const taskTypeMap: any = {
	[TaskTypeEnum.DAILY_WORK_ORDER]: '日常工单',
	[TaskTypeEnum.SCHEDULE_WORK_ORDER]: '计划工单',
	[TaskTypeEnum.URGENT_WORK_ORDER]: '紧急工单'
};

export const timeLimitMap: any = {
	[TimeLimitEnum.UNIFORM_TIME_LIMIT]: '统一规定时限',
	[TimeLimitEnum.CUSTOM_TIME_LIMIT]: '自定义时限'
};
