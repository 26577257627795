import React, { Component, Fragment } from 'react';
import './index.less';
import * as redux from 'react-redux';
import { Props, State, monthItemInterface } from './interface';
import { Select, DatePicker, message } from 'antd';
import moment from 'moment';
const { Option } = Select;
const { connect } = redux;
const { RangePicker } = DatePicker;

class CardTimeSelect extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			timeSource: [],
			crtVal: props.defaultOption ? props.defaultOption : '',
			timeRest: new Date()
		};
	}
	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		const { dataOption } = nextProps;
		const customize = [
			{
				label: '自定义',
				value: 'customize'
			}
		];
		if (dataOption) {
			const newTimeSource = [...dataOption, ...customize];
			return {
				timeSource: newTimeSource
			};
		}
		return null;
	}
	handleTimeChange = (e: string) => {
		this.setState({
			crtVal: e
		});
		if (e && e !== 'customize') {
			const startDay = e === '0' ? Number(e) : Number(e) - 1;
			const startDateMonth = moment().subtract(startDay, 'months').format('YYYY-MM');
			const endTime = e === '0' ? moment().format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM');
			const endDay = moment(endTime).endOf('month').format('YYYY-MM-DD');
			const startDate = `${startDateMonth}-01 00:00:00`;
			const endDate = e === '0' ? endTime : `${endDay} 23:59:59`;
			this.props.onChange && this.props.onChange(startDate, endDate, e);
		} else {
			this.props.onChange && this.props.onChange();
		}
	};
	rangePickerChange = (time: any) => {
		if (time && Array.isArray(time)) {
			if (time[0] && time[1]) {
				const startDateMonth = moment(time[0]).format('YYYY-MM');
				const endDay = moment(time[1]).endOf('month').format('YYYY-MM-DD');
				const startDate = `${startDateMonth}-01 00:00:00`;
				const endDate = `${endDay} 23:59:59`;
				const duration = moment(endDate).diff(moment(startDate), 'months');
				if (duration >= 12) {
					message.error('仅支持一年内数据查询', 10);
					this.setState({
						timeRest: new Date()
					});
					return;
				}
				this.props.onChange && this.props.onChange(startDate, endDate);
			}
		}
	};
	disabledDate = (current: any) => {
		return current && current > moment().endOf('day'); // 选择时间要大于等于当前天。若今天不能被选择，去掉等号即可。
	};
	componentDidMount() {}
	componentWillUnmount() {}
	render() {
		const { timeRest } = this.state;
		return (
			<Fragment>
				<div className="cardTimeSelect">
					<Select
						dropdownClassName="cardTimeDropdown"
						value={this.state.crtVal}
						style={{ width: '120rem', height: '20rem', marginRight: '0.2rem' }}
						size="small"
						onChange={this.handleTimeChange}
					>
						{this.state.timeSource.map((item: monthItemInterface, idx: number) => {
							return (
								<Option value={item.value} key={idx}>
									{item.label}
								</Option>
							);
						})}
					</Select>
					<div className="cardDataPick">
						{this.state.crtVal === 'customize' ? (
							<RangePicker
								dropdownClassName="cardTimeDataPick"
								allowClear={false}
								key={timeRest}
								picker="month"
								format="YYYY-MM"
								bordered={false}
								onChange={this.rangePickerChange}
								disabledDate={this.disabledDate}
							></RangePicker>
						) : null}
					</div>
				</div>
			</Fragment>
		);
	}
}
const mapStateToProps = (store: {}) => {
	return store;
};
const mapDispatchToProps = (dispatch: void) => {
	return {
		dispatch
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CardTimeSelect);
