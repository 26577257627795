import React, { Component } from 'react';

import * as redux from 'react-redux';
import { Props, State } from './interface';
import classes from './index.module.css';
import Card from '@/components/Card';
import { Image } from 'antd';

const { table, longView } = classes;
const { connect } = redux;
class VehicleLive extends Component<Props, State> {
	timer: any;
	timerInter: any;
	speed: number;
	delay: number;
	scrollNumber: number;
	stop: boolean;
	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		return null;
	}

	constructor(props: Props) {
		super(props);
		this.speed = 50;
		this.delay = 1000;
		this.scrollNumber = 3;
		this.stop = true;
		this.state = {
			right: 0
		};
	}

	componentDidMount(): void {
		this.beigin();
	}
	componentDidUpdate(): void {
		this.beigin();
	}

	componentWillUnmount(): void {
		this.clearTime();
	}
	beigin = () => {
		const { data } = this.props;
		if (data?.length > this.scrollNumber) {
			this.clearTime();
			this.timerInter = setInterval(this.scroll, this.speed);
		}
	};

	clearTime = (): void => {
		clearTimeout(this.timer);
		clearInterval(this.timerInter);
	};

	up = () => {
		this.stop = true;
	};

	down = () => {
		this.stop = false;
	};

	scroll = () => {
		let { right } = this.state;
		const { data } = this.props;

		if (this.stop) {
			this.setState({
				right: (right -= 2)
			});
		}
		if (Math.abs(parseInt(right as any)) >= data.length * 138) {
			this.setState({
				right: 0
			});
		}
		if (Math.abs(parseInt(right as any)) % 138 === 0 && Math.abs(parseInt(right as any)) !== 0) {
			clearInterval(this.timerInter);
			this.timer = setTimeout(this.start, this.delay);
		}
	};

	start = () => {
		this.timerInter = setInterval(this.scroll, this.speed);
	};

	handleData = (i?: number) => {
		const { data } = this.props;
		return data.map((item: any, index: number) => {
			const imgUrl = `/security/v1.0.0/hik/getHikCrossRecordPath?aswSyscode=${item.aswSyscode}&picUri=${item.imageURL}`;
			return (
				<div key={i ? index * i : index}>
					<div className={classes.imgBox}>
						<Image src={imgUrl} width={118} />
					</div>
					<div className={classes.infoBox}>
						<div className={classes.infoName}>{item.plateNO} </div>
						<div className={classes.infoTime}>{item.time} </div>
					</div>
				</div>
			);
		});
	};

	render() {
		const { right } = this.state;
		return (
			<Card titleName="车辆实况">
				<div className={table}>
					<div id="todyFireIncidentBoxHid">
						<div
							className={longView}
							id="todyFireIncidentTabContext"
							style={{ right: right + 'rem' }}
							onMouseOver={this.down}
							onMouseOut={this.up}
						>
							{this.handleData(10)}
						</div>
					</div>
				</div>
			</Card>
		);
	}
}

const mapStateToProps = (store: {}) => store;
const mapDispatchToProps = (dispatch: Function) => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(VehicleLive);
