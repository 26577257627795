import React, { PureComponent } from 'react';
import Echarts from 'echarts-for-react';
interface State {}

interface Props {
	option: object;
	data: object;
	runAction?: Function;
	getOption: Function;
	style?: object;
}
export default class BaseChart extends PureComponent<Props, State> {
	private chartRef: any;
	componentDidMount() {
		const { runAction } = this.props;

		if (this.chartRef && runAction) {
			const chartIns = this.chartRef.getEchartsInstance();
			window.setTimeout(() => {
				runAction(chartIns);
			}, 300);
		}
	}
	render() {
		const { option, data, getOption, style } = this.props;

		const finalOption = getOption(option, data);
		const finalStyle = getStyle(style);

		return (
			<Echarts
				ref={(ref) => {
					this.chartRef = ref;
				}}
				style={finalStyle}
				option={finalOption}
				notMerge
				lazyUpdate
			/>
		);
	}
}

function getStyle(style?: AnyObject): AnyObject {
	return Object.assign({ position: 'relative' }, style);
}
