import { Function } from '@babel/types';
import React, { Component, useState, memo, useRef, useEffect, useMemo } from 'react';
import DataSelect from '@/components/DataSelect/index';
import './index.less';

interface Props {
	timeChange?: any;
	tableChange: any;
	timeOption?: any[];
	tableOption: any[];
	isShowTime?: boolean;
	defaultTime?: any;
}
const TimeAndTab = (props: Props) => {
	const [active, setActive] = useState<number>(0);
	const { timeChange, tableChange, timeOption = [], tableOption = [], defaultTime, isShowTime } = props;
	useEffect(() => {
		tableChange && tableChange(active);
	}, [active]);

	return (
		<div className="timeAndTab">
			<div className="table">
				{tableOption?.map((item: any, index: number) => {
					return (
						<div
							className={active === index ? 'active' : ''}
							key={index}
							onClick={() => {
								setActive(index);
							}}
						>
							{item}
						</div>
					);
				})}
			</div>
			{isShowTime ? (
				<div className="time-select">
					<DataSelect dataOption={timeOption} defaultOption={defaultTime} onChange={timeChange}></DataSelect>
				</div>
			) : null}
		</div>
	);
};

export default TimeAndTab;
