const data: string[] = [];
for (let i = 0; i < 24; i++) {
	if (i < 10) {
		data.push('0' + i + ':00');
	} else {
		data.push(i + ':00');
	}
}

export const getOption = (data1: any[], data2: number[], allData: any, finishedData: any) => {
	const all: any[] = [];
	const finish: any[] = [];
	const allAx = [0];
	const finishAx = [0];
	allData?.map((item: any) => {
		allAx.push(parseInt(item.name));
	});
	finishedData?.map((item: any) => {
		finishAx.push(parseInt(item.name));
	});

	data.forEach((element: any) => {
		const currentFINISHED = finishedData?.find((item: { name: any }) => {
			return parseInt(item.name) === parseInt(element);
		});
		const currentALL = allData?.find((item: { name: any }) => {
			return parseInt(item.name) === parseInt(element);
		});

		all.push(currentALL || 0);
		finish.push(currentFINISHED || 0);
	});
	const allNum: any = [];
	const finishedNum: any = [];
	let date1Num: any = 0;
	let date2Num: any = 0;
	let date3Num: any = 50;

	data1?.map((item) => {
		allNum.push(item);
	});
	data1?.map((item) => {
		finishedNum.push(item);
	});

	if (allNum?.length) {
		date1Num = allNum?.sort((a: number, b: number) => {
			return b - a;
		})[0];
	}

	if (finishedNum?.length) {
		date2Num = finishedNum?.sort((a: number, b: number) => {
			return b - a;
		})[0];
	}
	if (date2Num !== 0 || date1Num !== 0) {
		date3Num = date2Num > date1Num ? date2Num || 50 : date1Num || 50;
	}

	const dataText = [
		{
			name: '事件数',
			type: 'line',
			smooth: false,

			symbolSize: 4,
			data: all || [],
			itemStyle: {
				normal: {
					color: 'rgba(3, 230, 3, 1)'
				}
			},
			lineStyle: {
				width: 1
			}
		},
		{
			name: '已处理',
			type: 'line',
			smooth: false,
			symbolSize: 4,
			data: finish || [],
			itemStyle: {
				normal: {
					color: '#ca603f'
				}
			},
			lineStyle: {
				width: 1
			}
		}
	];

	return {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				// 坐标轴指示器，坐标轴触发有效
				type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
			}
		},
		legend: {
			x: 'center',
			bottom: '8%',
			textStyle: {
				color: '#c1cadf',
				fontSize: 12
			}
		},
		grid: {
			left: '6%',
			right: '7%',
			top: '5%',
			bottom: '20%',
			containLabel: true
		},
		toolbox: {
			show: true,
			orient: 'vertical',
			x: 'right',
			y: 'center'
		},
		xAxis: [
			{
				type: 'category',
				boundaryGap: false,
				data: data,
				axisLine: {
					onZero: false,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// axisTick: {
				// 	inside: true
				// },
				axisLabel: {
					interval: 3,
					color: '#c1cadf',
					fontSize: '12'
				}
			}
		],
		yAxis: [
			{
				type: 'value',
				show: true,
				min: data1?.length || data2?.length ? null : 0,
				max: date3Num,
				axisLine: {
					show: true,
					onZero: false,
					lineStyle: {
						color: '#00A0E5'
					}
				},
				// axisTick: {
				// 	inside: true
				// },
				axisLabel: {
					interval: 0,
					color: '#ffffff',
					fontSize: '12'
				},
				splitLine: {
					show: false
				}
			}
		],
		series: dataText
	};
};
