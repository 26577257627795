import { Button } from 'antd';
import React, { Fragment, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { Tabs } from 'antd';
import moment from 'moment';
import { Backend } from '@/api';
import classNames from './index.module.css';
import Card from '@/components/Card';
import DataSelect from '@/components/DataSelect/index';
import Bar from '@/components/Charts/Bar/index';
import { geneFireFightingLine } from './option';
import { storage } from '@/utils';

interface Props {
	pageInfo?: any;
}
const RightTrend = (props: Props, ref: any) => {
	const [startTime, setStartTime] = useState<string>(
		`${moment().subtract(6, 'months').format('YYYY-MM')}-01 00:00:00`
	);
	const [endTime, setEndTime] = useState<string>(
		`${moment(moment().add(-1, 'M')).endOf('month').format('YYYY-MM-DD')} 23:59:59`
	);
	const [fireFighting, setFireFighting] = useState<any>({});
	const [typeTime, setTypeTime] = useState<string>('6');

	useImperativeHandle(ref, () => {
		return {
			getLineData
		};
	});

	const { pageInfo = {} } = props;

	const optionList = [
		{
			label: '近六月',
			value: '6'
		},
		{
			label: '近一年',
			value: '12'
		}
	];

	useEffect(() => {
		getLineData();
	}, []);
	const getdataList = (startTime: string, endTime: string) => {
		const startTimeUnix: any = moment(startTime).unix();
		const endTimeUnix: any = moment(endTime).unix();
		const limeList = [];
		let currentTime: any = startTimeUnix;
		while (currentTime <= endTimeUnix) {
			const time = moment(parseInt(currentTime) * 1000).format('YYYY-MM-DD');
			const year = time.split('-')[0];
			const month = time.split('-')[1];
			limeList.push(`${year}.${month}`);
			currentTime = moment(time).add(1, 'M').unix();
		}
		return limeList;
	};

	const timeChange = (startDate: any, endDate: any, typeTime: string) => {
		const currentArea = storage.getSession('CURRENTAREA');
		if (startDate && endDate) {
			const obj: any = { visitType: 'register' };
			if (typeTime) {
				obj['days'] = typeTime;
			} else {
				obj['endTime'] = endDate;
				obj['startTime'] = startDate;
			}
			Backend.getVisitTrend({
				query: {
					areaId: currentArea === 'all' ? '' : currentArea,
					...obj
				}
			}).then((res: IotResponse) => {
				if (res.code === 0) {
					const data = res.data;
					setTypeTime(typeTime);
					setFireFighting(data);
					setStartTime(startDate);
					setEndTime(endDate);
				}
			});
		}
	};

	const getLineData = () => {
		const obj: any = { visitType: 'register' };
		if (typeTime) {
			obj['days'] = typeTime;
		} else {
			obj['endTime'] = endTime;
			obj['startTime'] = startTime;
		}
		const currentArea = storage.getSession('CURRENTAREA');
		Backend.getVisitTrend({
			query: {
				areaId: currentArea === 'all' ? '' : currentArea,
				...obj
			}
		}).then((res: IotResponse) => {
			if (res.code === 0) {
				const data = res.data;
				setFireFighting(data);
			}
		});
	};
	const monthList = getdataList(startTime, endTime);
	const fireFightingLineData = geneFireFightingLine(fireFighting, monthList);
	return (
		<Card titleName="访客趋势">
			<div className={classNames.dataSelect}>
				<DataSelect dataOption={optionList} defaultOption="6" onChange={timeChange}></DataSelect>
			</div>
			<Bar data={fireFightingLineData} style={{ height: '100%', width: '100%' }}></Bar>
		</Card>
	);
};

export default forwardRef(RightTrend);
