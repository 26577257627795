/* eslint-disable react/no-string-refs */
import React, { PureComponent } from 'react';
import * as ReactDom from 'react-dom';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
highcharts3d(Highcharts); //初始化这个模块
interface State {}
interface Props {
	dataSource?: any[];
	title?: string;
	colors?: string[];
	newOption?: object;
}
export default class Pie3D extends PureComponent<Props, State> {
	// private chartRef: any;
	componentDidMount() {}
	render() {
		const { title, dataSource, colors, newOption } = this.props;
		const options: Highcharts.Options = {
			chart: {
				type: 'pie',
				marginTop: 10,
				height: '160rem', // width: '220rem',
				backgroundColor: 'transparent',
				options3d: {
					enabled: true,
					alpha: 40,
					beta: 10
				}
			},
			title: {
				text: ''
			},
			colors: colors || ['#50A6EF', '#F1CD49', '#A1FF8F'],
			tooltip: {
				pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					depth: 10,
					dataLabels: {
						enabled: false,
						format: '{point.name}'
					}
				}
			},
			series: [
				{
					type: 'pie',
					name: title,
					data: dataSource
				}
			],
			...newOption
		};
		return <HighchartsReact highcharts={Highcharts} options={options} />;
	}
}
