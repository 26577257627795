export const optionList = [
	{
		label: '近六月',
		value: '6'
	},
	{
		label: '近一年',
		value: '12'
	}
];
